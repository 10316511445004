import React from "react";
import { FaMapMarkerAlt, FaMobileAlt, FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

import ContactUsMessage from "./ContactUsMessage";
import { BiSolidTime } from "react-icons/bi";

export default function ContactUsBody() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 my-3">
          Thank you for visiting Epping Dental Centre online! We are committed
          to helping you achieve optimal oral health and a beautiful smile in
          Epping, NSW! Our dentists and team will take the time to provide you
          with an individualised treatment plan to achieve your goals.{" "}
          <a href={"tel:" + process.env.REACT_APP_PHONE}>Call us</a> today or{" "}
          <a href="/book-now">Book Now</a> to schedule your appointment.
        </div>

        <div className="col-lg-6">
          <div className="row">
            <div className="col-1"></div>
            <div className="col-11">
              <h3>{process.env.REACT_APP_COMPANY}</h3>
            </div>
            <div className="col-1">
              <FaMapMarkerAlt />
            </div>
            <div className="col-11 mb-2">
              {process.env.REACT_APP_ADDRESS.split(",")[0]},<br />
              {process.env.REACT_APP_ADDRESS.split(",")[1]}
              {", "}
              {process.env.REACT_APP_ADDRESS.split(",")[2]}
            </div>
            <div className="col-1">
              <FaPhoneAlt />
            </div>
            <div className="col-11 mb-2">
              <a href={"tel:" + process.env.REACT_APP_PHONE}>
                {process.env.REACT_APP_PHONE}
              </a>
            </div>
            <div className="col-1">
              <FaMobileAlt />
            </div>
            <div className="col-11 mb-2">
              <a href={"tel:" + process.env.REACT_APP_MOBILE}>
                {process.env.REACT_APP_MOBILE}
              </a>
            </div>
            <div className="col-1">
              <MdEmail />
            </div>
            <div className="col-11 mb-2">
              <a href={"mailto:" + process.env.REACT_APP_EMAIL}>
                {process.env.REACT_APP_EMAIL}
              </a>
            </div>
            <div className="col-1">
              <BiSolidTime />
            </div>
            <div className="col-11">
              <div className="row">
                <div className="col-12">
                  <h5>Office Hours:</h5>
                </div>
                <div className="col-4">Monday - Friday:</div>
                <div className="col-8"> 9:00am - 8:00pm</div>
                {/* <div className="col-4">Tuesday:</div>
                <div className="col-8"> 8:00am - 5:00pm</div>
                <div className="col-4">Wednesday:</div>
                <div className="col-8"> 8:00am - 8:00pm</div>
                <div className="col-4">Thursday:</div>
                <div className="col-8"> 8:00am - 5:00pm</div>
                <div className="col-4">Friday:</div>
                <div className="col-8"> 8:00am - 5:00pm</div> */}
                <div className="col-4">Saturday:</div>
                <div className="col-8"> 9:00am - 8:00pm</div>
                <div className="col-4">Sunday:</div>
                <div className="col-8"> 9:00am - 5:00pm</div>
                {/* <div className="col-12">
                  <hr />
                </div>
                <div className="col-4">Lunch:</div>
                <div className="col-8">12:00pm - 1:00pm</div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3316.578898339735!2d151.07827821199962!3d-33.7715460139178!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12a682b5543781%3A0xc7b62432f7be0044!2sEpping%20Dental%20Centre!5e0!3m2!1sen!2sau!4v1686948449322!5m2!1sen!2sau"
            width="100%"
            height="450"
            title="Epping Dental Centre"
            style={{ border: "0" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="col-12 my-3">
          <ContactUsMessage />
        </div>
      </div>
    </div>
  );
}
