import "./AboutUs.css";
import AboutUsTitleImg from "./edc-aboutus-title.jpg";
import Title from "../MyNavbar/Title";
import AboutUsBody from "./AboutUsBody";
import NoGapButton from "../Home/NoGapButton";

export default function AboutUs() {
  return (
    <>
      <Title bg_img={AboutUsTitleImg} title="About Us" />
      <AboutUsBody />
      <NoGapButton />
    </>
  );
}
