import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";

import AuthContext from "../../context/AuthContext";
import UserNotifyColumn from "./UserNotifyColumn";
import UserNotifyItem from "./UserNotifyItem";

export default function UserNotify() {
  const { token } = useContext(AuthContext);

  const [bookedUsers, setBookedUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      if (!token) return;
      const authStr = "Bearer " + token;
      try {
        const resDoc = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/user/updatedNotifs",
          {
            headers: { Authorization: authStr },
          }
        );
        if (resDoc.status === 200) setBookedUsers([...resDoc.data]);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUsers();
  }, [token]);

  return (
    <div className="container-fluid">
      <h1 className="text-center">Manage User Notifications</h1>
      <div className="container-fluid bg-light text-dark">
        <UserNotifyColumn />
        {bookedUsers.map((user, index) => (
          <UserNotifyItem
            key={user._id}
            index={index}
            user={user}
            setBookedUsers={setBookedUsers}
          />
        ))}
      </div>
    </div>
  );
}
