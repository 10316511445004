import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import MyNavbar from "./components/MyNavbar/MyNavbar";
import Home from "./components/Home/Home";
import BookNow from "./components/BookNow/BookNow";
import AuthProvider from "./context/AuthProvider";
import UserRoute from "./Routes/UserRoute";
import BookNowAppt from "./components/BookNow/BookNowAppt";
import CreateSchedule from "./components/CreateSchedule/CreateSchedule";
import UserManage from "./components/UserManage/UserManage";
import BookManage from "./components/BookManage/BookManage";
import ManageInsurance from "./components/ManageInsurance/ManageInsurance";
import ManageService from "./components/ManageService/ManageService";
import ManageReason from "./components/ManageReason/ManageReason";
import StaffManage from "./components/StaffManage/StaffManage";
import StaffRoute from "./Routes/StaffRoute";
import StaffProfile from "./components/Staff/StaffProfile";
import ListProvider from "./context/ListProvider";
import AboutUs from "./components/AboutUs/AboutUs";
import AboutUsBodyStaff from "./components/AboutUs/AboutUsBodyStaff";
import UserResetPassword from "./components/User/UserResetPassword";
import StaffSignIn from "./components/Staff/StaffSignIn";
import StaffResetPassword from "./components/Staff/StaffResetPassword";
import ContactUs from "./components/ContactUs/ContactUs";
import UserDashboard from "./components/User/UserDashboard";
import ServiceDetail from "./components/Service/ServiceDetail";
import UserNotify from "./components/UserNotify/UserNotify";

export default function App() {
  return (
    <AuthProvider>
      <ListProvider>
        <BrowserRouter>
          <MyNavbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/aboutus/:staffName" element={<AboutUsBodyStaff />} />
            <Route
              exact
              path="/service/:serviceName"
              element={<ServiceDetail />}
            />
            <Route
              path="/user/resetPassword/:emailToken"
              element={<UserResetPassword />}
            />
            <Route
              path="/staff/resetPassword/:emailToken"
              element={<StaffResetPassword />}
            />
            <Route path="/book-now" element={<BookNow />} />
            <Route
              path="/book-appt/:appt_id/:reason_id"
              element={<BookNowAppt />}
            />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/edc-staff" element={<StaffSignIn />} />
            <Route element={<StaffRoute />}>
              <Route path="/manage/booking" element={<BookManage />} />
              <Route path="/manage/staff" element={<StaffManage />} />
              <Route path="/manage/user" element={<UserManage />} />
              <Route path="/manage/notifyuser" element={<UserNotify />} />
              <Route path="/manage/insurance" element={<ManageInsurance />} />
              <Route path="/manage/service" element={<ManageService />} />
              <Route path="/manage/reason" element={<ManageReason />} />

              <Route path="/manage/schedule" element={<CreateSchedule />} />
              <Route path="/staff/profile" element={<StaffProfile />} />
            </Route>
            <Route element={<UserRoute />}>
              <Route path="/user/dashboard" element={<UserDashboard />} />
            </Route>
            <Route path="*" element={<h5>404: Page not found.</h5>} />
          </Routes>
        </BrowserRouter>
      </ListProvider>
    </AuthProvider>
  );
}
