import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import { BsFillPersonPlusFill } from "react-icons/bs";

export default function StaffSignUpModal() {
  const [showRegModal, setShowRegModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState();

  const [newStaff, setNewStaff] = useState();

  const [mobNrError, setMobNrError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [regEnabled, setRegEnabled] = useState(false);
  const [regDone, setRegDone] = useState(false);

  /*
  StaffInterface {
  _id?: string;
  fname?: string;
  lname?: string;
  email?: string;
  password?: string;
  password2?: string;
  phone?: string;
  role?: string;
  status?: string;
  address?: AddressInterface;
}*/

  useEffect(() => {
    setRegEnabled(
      newStaff &&
        newStaff.fname &&
        newStaff.lname &&
        isMobileNr(newStaff.phone) &&
        isValidEmail(newStaff.email) &&
        newStaff.password &&
        newStaff.password2
    );
  }, [newStaff]);

  const handleChange = (event) => {
    setNewStaff((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  function isMobileNr(mobileNr) {
    return /^04(\s?[0-9]{2}\s?)([0-9]{3}\s?[0-9]{3}|[0-9]{2}\s?[0-9]{2}\s?[0-9]{2})$/.test(
      mobileNr
    );
  }
  const handleMobileChange = (event) => {
    if (isMobileNr(event.target.value)) {
      setMobNrError(false);
    } else {
      setMobNrError(true);
    }
    handleChange(event);
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleEmailChange = (event) => {
    if (isValidEmail(event.target.value)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
    handleChange(event);
  };

  function isValidPassword(password) {
    // six or more characters
    return /^.{6,}$/.test(password);
  }
  const handlePasswordChange = (event) => {
    if (isValidPassword(event.target.value)) {
      setPasswordError(false);
    } else {
      setPasswordError(true);
    }
    handleChange(event);
  };

  async function signUp() {
    if (newStaff.password !== newStaff.password2) {
      setErrorMsg("Confirm password doesn't match! Try again.");
      return;
    }
    delete newStaff.password2;

    try {
      const resDoc = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/staff/signUp",
        newStaff
      );
      if (resDoc.status === 201) {
        setRegDone(true);
      }
    } catch (error) {
      // axios Handling Errors: https://axios-http.com/docs/handling_errors
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        setErrorMsg(error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        setErrorMsg(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log("Error", error.message);
        console.error(error);
      }
    }
  }

  return (
    <>
      <div onClick={() => setShowRegModal(true)}>Create Account</div>

      <Modal show={showRegModal} onHide={() => setShowRegModal(false)}>
        <Modal.Header className="bg-warning" closeButton>
          <Modal.Title>
            <BsFillPersonPlusFill /> Create Staff Account
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="card-body">
            <Alert
              show={!!errorMsg}
              variant="danger"
              onClose={() => setErrorMsg()}
              dismissible
            >
              Error: {errorMsg}.
            </Alert>
            <Alert show={regDone} variant="success">
              Account created. Please contact admin to activate your account.
            </Alert>
            <div className="row">
              <div className="col-6 mb-2">
                <input
                  className="form-control"
                  required
                  type="text"
                  name="fname"
                  onChange={handleChange}
                  placeholder="👤 First name*"
                />
              </div>
              <div className="col-6 mb-2">
                <input
                  className="form-control"
                  required
                  type="text"
                  name="lname"
                  onChange={handleChange}
                  placeholder="Last name 👤*"
                />
              </div>
              <div className="col-6">
                <input
                  className="form-control"
                  type="text"
                  name="phone"
                  onChange={handleMobileChange}
                  placeholder="☎︎ Mobile Number*"
                />
                {mobNrError && (
                  <small style={{ color: "red" }}>
                    enter valid mobie number:
                    <br />
                    e.g., 0450123456
                  </small>
                )}
              </div>

              <div className="col-6 mb-2">
                <input
                  className="form-control"
                  required
                  type="email"
                  name="email"
                  onChange={handleEmailChange}
                  placeholder="📧 email*"
                />
                {emailError && (
                  <small style={{ color: "red" }}>
                    enter valid email address
                  </small>
                )}
              </div>
              <div className="col-6 mb-2">
                <input
                  className="form-control"
                  required
                  type="password"
                  name="password"
                  placeholder="🔒Password*"
                  onChange={handlePasswordChange}
                />
                {passwordError && (
                  <small style={{ color: "red" }}>
                    enter password with 6 or more characters
                  </small>
                )}
              </div>
              <div className="col-6 mb-2">
                <input
                  className="form-control"
                  required
                  type="password"
                  name="password2"
                  onChange={handleChange}
                  placeholder="🔒confirm password*"
                />
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="card-footer">
            {regDone ? (
              <a href="/" className="btn btn-success">
                Return
              </a>
            ) : (
              <button
                className="btn btn-warning"
                disabled={!regEnabled}
                onClick={signUp}
              >
                <h5>
                  <BsFillPersonPlusFill /> Register
                </h5>
              </button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
