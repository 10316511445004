import { useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import UserBookings from "../User/UserBookings";

export default function UserManageItemModal({ user_id, userFName, userLName }) {
  const [show, setShow] = useState(false);
  const [selKey, setSelKey] = useState("current");

  return (
    <>
      <button className="btn btn-link" onClick={() => setShow(true)}>
        {userFName}
      </button>

      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {userFName} {userLName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Header>
              <Nav
                variant="tabs"
                defaultActiveKey="current"
                onSelect={(selectedKey) => setSelKey(selectedKey)}
              >
                <Nav.Item>
                  <Nav.Link eventKey="current">Appointment</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="history">History</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>
            <Card.Body>
              <UserBookings current={selKey === "current"} user_id={user_id} />
            </Card.Body>
          </Card>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
