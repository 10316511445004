import React, { useState, useContext } from "react";
import axios from "axios";
import { FaSave } from "react-icons/fa";

import AuthContext from "../../context/AuthContext";
import ListContext from "../../context/ListContext";

export default function UserManageEdit({ index, user }) {
  const { token } = useContext(AuthContext);
  const { insuranceList } = useContext(ListContext);
  const [editUser, setEditUser] = useState({
    ...user,
    insurance_id: user.insurance_id._id, // insurance_id has been populated into an object, but we need the original _id here, so restore to original _id
  });

  const handleChange = (event) => {
    setEditUser((prevEx) => {
      return {
        ...prevEx,
        [event.target.name]: event.target.value,
      };
    });
  };

  async function saveUpdate() {
    const authStr = "Bearer " + token;
    try {
      const resDoc = await axios.put(
        process.env.REACT_APP_BACKEND_URL + "/user/update/" + user._id,
        editUser,
        {
          headers: { Authorization: authStr },
        }
      );
      if (resDoc.status === 201) {
        // updated - refresh page
        window.location.reload(false);
      } else {
        console.error(resDoc.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className={index % 2 ? "mt-1" : "mt-1 bg-white"}>
      <div className="row">
        <div className="col-2">
          <input
            type="text"
            className="form-control"
            name="fname"
            onChange={handleChange}
            defaultValue={editUser.fname}
          />
        </div>
        <div className="col-1">
          <input
            type="text"
            className="form-control"
            name="lname"
            onChange={handleChange}
            defaultValue={editUser.lname}
          />
        </div>
        <div className="col-2">{editUser.phone}</div>
        <div className="col-2">{editUser.email}</div>
        <div className="col-1">
          <input
            className="form-control"
            required
            type="date"
            name="DOB"
            onChange={handleChange}
            defaultValue={editUser.DOB && editUser.DOB.substring(0, 10)}
          />
        </div>
        <div className="col-1">
          <select
            className="form-select"
            defaultValue={editUser.status}
            name="status"
            onChange={handleChange}
          >
            <option disabled>status</option>
            <option value="active">active</option>
            <option value="inactive">inactive</option>
            <option value="manual">manual</option>
            <option value="disabled">disabled</option>
          </select>
        </div>

        <div className="col-2">
          <select
            className="form-select"
            name="insurance_id"
            value={editUser.insurance_id}
            onChange={handleChange}
          >
            {insuranceList.map((insurance) => (
              <option
                key={insurance._id}
                value={insurance._id}
                // selected={editUser.insurance_id._id === insurance._id}
              >
                {insurance.name}
              </option>
            ))}
          </select>
        </div>

        <div className="col-1">
          <button className="btn btn-link" onClick={saveUpdate}>
            <FaSave />
          </button>
        </div>
      </div>
    </div>
  );
}
