import { useState } from "react";
import Modal from "react-bootstrap/Modal";

export default function UserBookingsCencalModal({ cancelAppt, appt_id }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button className="btn btn-warning" onClick={handleShow}>
        Cancel Appointment
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Click on <b>Cancel Appointment</b> button below to cancel.
          </p>
          <p>
            Or click on <b>Close</b> to return without cancelling.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleClose}>
            Close
          </button>
          <button
            className="btn btn-danger"
            onClick={() => cancelAppt(appt_id)}
          >
            Cancel Appointment
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
