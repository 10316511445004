import React, { useState, useContext, useEffect } from "react";
import axios from "axios";

import AuthContext from "../../context/AuthContext";
import ManageInsuranceColumn from "./ManageInsuranceColumn";
import ManageInsuranceItem from "./ManageInsuranceItem";
import ManageInsuranceAdd from "./ManageInsuranceAdd";
import ManageInsuranceEdit from "./ManageInsuranceEdit";

export default function ManageInsurance() {
  const { token } = useContext(AuthContext);
  const [allInsuranceList, setAllInsuranceList] = useState([]);
  useEffect(() => {
    const fetchService = async () => {
      if (!token) return;
      const authStr = "Bearer " + token;
      try {
        const resDoc = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/insurance/all",
          {
            headers: { Authorization: authStr },
          }
        );
        if (resDoc.data.length > 0) {
          setAllInsuranceList([...resDoc.data]);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchService();
  }, [token]);

  const [editInsurance_id, setEditInsurance_id] = useState("");
  const setEdit = (insurance_id) => {
    setEditInsurance_id(insurance_id);
  };

  return (
    <div className="container-fluid bg-light text-dark">
      <h1 className="text-center">Manage Insurances</h1>
      <h5>Add a New Insurance:</h5>
      <ManageInsuranceAdd />
      <ManageInsuranceColumn />
      {allInsuranceList &&
        allInsuranceList.map((insurance, index) => {
          if (insurance._id === editInsurance_id) {
            return (
              <ManageInsuranceEdit
                key={index}
                index={index}
                insurance={insurance}
              />
            );
          } else {
            return (
              <ManageInsuranceItem
                key={index}
                index={index}
                insurance={insurance}
                setEdit={setEdit}
              />
            );
          }
        })}
    </div>
  );
}
