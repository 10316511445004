import React from "react";

export default function ManageServiceColumn() {
  return (
    <div className="bg-primary text-white">
      <div className="row px-2">
        <div className="col-11 col-lg-1">
          <h5>Name</h5>
        </div>
        <div className="col-11 col-lg-1">
          <h5>Seq</h5>
        </div>
        <div className="col-11 col-lg-1">
          <h5>Icon</h5>
        </div>
        <div className="col-11 col-lg-2">
          <h5>Image</h5>
        </div>
        <div className="col-11 col-lg-2">
          <h5>ShortNote</h5>
        </div>
        <div className="col-11 col-lg-3">
          <h5>Note</h5>
        </div>
        <div className="col-11 col-lg-1">
          <h5>Status</h5>
        </div>{" "}
        <div className="col-11 col-lg-1">
          <h5>Actions</h5>
        </div>
      </div>
    </div>
  );
}
