import React, { useState, useContext, useEffect } from "react";
import axios from "axios";

import AuthContext from "../../context/AuthContext";
import ManageReasonColumn from "./ManageReasonColumn";
import ManageReasonItem from "./ManageReasonItem";
import ManageReasonAdd from "./ManageReasonAdd";
import ManageReasonEdit from "./ManageReasonEdit";

export default function ManageReason() {
  const { token } = useContext(AuthContext);
  const [allReasonList, setAllReasonList] = useState([]);
  useEffect(() => {
    const fetchService = async () => {
      if (!token) return;
      const authStr = "Bearer " + token;
      try {
        const resDoc = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/reason/all",
          {
            headers: { Authorization: authStr },
          }
        );
        if (resDoc.data.length > 0) {
          setAllReasonList([...resDoc.data]);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchService();
  }, [token]);

  const [editReason_id, setEditReason_id] = useState("");
  const setEdit = (reason_id) => {
    setEditReason_id(reason_id);
  };

  return (
    <div className="container-fluid bg-light text-dark">
      <h1 className="text-center">Manage Reasons</h1>
      <h5>Add a New Reason:</h5>
      <ManageReasonAdd />
      <ManageReasonColumn />
      {allReasonList &&
        allReasonList.map((reason, index) => {
          if (reason._id === editReason_id) {
            return (
              <ManageReasonEdit key={index} index={index} reason={reason} />
            );
          } else {
            return (
              <ManageReasonItem
                key={index}
                index={index}
                reason={reason}
                setEdit={setEdit}
              />
            );
          }
        })}
    </div>
  );
}
