import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import AuthContext from "../../context/AuthContext";

export default function ContactUsMessage() {
  const { token } = useContext(AuthContext);
  const [userMsg, setUserMsg] = useState();
  const navigate = useNavigate();

  const [validMobile, setValidMobile] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [msgReady, setMsgReady] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      if (!token) return;
      const authStr = "Bearer " + token;
      try {
        const resDoc = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/user/getSelf",
          {
            headers: { Authorization: authStr },
          }
        );
        if (resDoc && resDoc.status === 200) {
          const loginUser = resDoc.data;
          setUserMsg((preState) => {
            return {
              ...preState,
              userName: loginUser.fname + " " + loginUser.lname,
              email: loginUser.email,
              phone: loginUser.phone,
            };
          });
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchUser();
  }, [token]);

  useEffect(() => {
    setMsgReady(
      !!userMsg &&
        !!userMsg.userName &&
        isValidMobile(userMsg.phone) &&
        isValidEmail(userMsg.email) &&
        !!userMsg.subject &&
        !!userMsg.message
    );
  }, [userMsg]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserMsg((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  function isValidMobile(mobileNr) {
    return /^04(\s?[0-9]{2}\s?)([0-9]{3}\s?[0-9]{3}|[0-9]{2}\s?[0-9]{2}\s?[0-9]{2})$/.test(
      mobileNr
    );
  }
  const handleMobileChange = (event) => {
    if (isValidMobile(event.target.value)) {
      setValidMobile(true);
    } else {
      setValidMobile(false);
    }
    handleChange(event);
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleEmailChange = (event) => {
    if (isValidEmail(event.target.value)) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
    handleChange(event);
  };

  async function sendMsg() {
    try {
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/user/message",
        userMsg
      );
      navigate("/");
    } catch (error) {
      console.error(error.response.data.message);
    }
  }

  return (
    <div className="card text-bg-light shadow">
      <div className="card-header">
        <div className="card-title h3">
          Message to {process.env.REACT_APP_COMPANY}
        </div>
      </div>

      <div className="card-body">
        <div className="row">
          <div className="col-lg-6">
            <div className="row">
              <div className="col-12 mb-2">
                <label htmlFor="msg-name">Your Name*:</label>
                <input
                  type="text"
                  id="msg-name"
                  className="form-control"
                  name="userName"
                  onChange={handleChange}
                  defaultValue={userMsg && userMsg.userName}
                  placeholder="John Smith"
                />
              </div>
              <div className="col-12 col-md-6 mb-2">
                <label htmlFor="msg-phone">Your phone*:</label>
                <input
                  type="text"
                  className="form-control"
                  id="msg-phone"
                  name="phone"
                  onChange={handleMobileChange}
                  defaultValue={userMsg && userMsg.phone}
                  placeholder="0450123456"
                />
                {!validMobile && (
                  <small style={{ color: "red" }}>
                    enter valid mobie number:
                    <br />
                    e.g., 0450123456
                  </small>
                )}
              </div>
              <div className="col-12 col-md-6 mb-2">
                <label htmlFor="msg-email">Your email*:</label>
                <input
                  type="email"
                  className="form-control"
                  id="msg-email"
                  name="email"
                  onChange={handleEmailChange}
                  defaultValue={userMsg && userMsg.email}
                  placeholder="name@example.com"
                />
                {!validEmail && (
                  <small style={{ color: "red" }}>
                    enter valid email address
                  </small>
                )}
              </div>

              <div className="col-12 mb-2">
                <label htmlFor="msg-subject">Message Subject*</label>
                <input
                  type="text"
                  className="form-control"
                  id="msg-subject"
                  name="subject"
                  onChange={handleChange}
                  placeholder="Message Subject"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <label htmlFor="msg-textarea">Your Message*:</label>
            <textarea
              className="form-control"
              id="msg-textarea"
              rows="7"
              name="message"
              onChange={handleChange}
              placeholder="Your Message..."
            />
          </div>
        </div>
      </div>

      <div className="card-footer">
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-success"
            disabled={!msgReady}
            onClick={sendMsg}
          >
            Send Us a Message
          </button>
        </div>
      </div>
    </div>
  );
}
