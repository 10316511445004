import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import AuthContext from "../../context/AuthContext";

import StaffManageColumn from "./StaffManageColumn";
import StaffManageItem from "./StaffManageItem";
import StaffManageEdit from "./StaffManageEdit";

export default function StaffManage() {
  const { token } = useContext(AuthContext);

  const [allStaffs, setAllStaffs] = useState([]);

  useEffect(() => {
    const fetchStaffs = async () => {
      if (!token) return;
      const authStr = "Bearer " + token;
      try {
        const resDoc = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/staff/all",
          {
            headers: { Authorization: authStr },
          }
        );
        setAllStaffs([...resDoc.data]);
      } catch (error) {
        console.error(error);
      }
    };
    fetchStaffs();
  }, [token]);

  const [editStaffId, setEditStaffId] = useState("");
  const setEdit = (staffId) => {
    setEditStaffId(staffId);
  };

  return (
    <div className="container-fluid">
      <h1 className="text-center">Manage Staff</h1>
      <div className="container-fluid bg-light text-dark">
        <StaffManageColumn />
        {allStaffs.map((staff, index) => {
          if (staff._id === editStaffId) {
            return <StaffManageEdit key={index} index={index} staff={staff} />;
          } else {
            return (
              <StaffManageItem
                key={staff._id}
                index={index}
                staff={staff}
                setEdit={setEdit}
              />
            );
          }
        })}
      </div>
    </div>
  );
}
