import React, { useState, useContext } from "react";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import { BsFillPersonCheckFill, BsPersonFillGear } from "react-icons/bs";

// import "./Staff.css";
import AuthContext from "../../context/AuthContext";
import StaffSignUpModal from "./StaffSignUpModal";
import { useNavigate } from "react-router-dom";
import StaffForgotPassword from "./StaffForgotPassword";
// import { Spinner, Alert, Modal } from "react-bootstrap";

export default function StaffSignIn() {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const [errorState, setErrorState] = useState();

  const [loginStaff, setLoginStaff] = useState({});
  const [forgotPassword, setForgotPassword] = useState(false);

  const handleChange = (event) => {
    setLoginStaff((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  async function signIn() {
    try {
      const resDoc = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/staff/signIn",
        { email: loginStaff.email, password: loginStaff.password }
      );
      if (resDoc.status === 201) {
        login(
          resDoc.data.token,
          resDoc.data.staff_id,
          resDoc.data.fname,
          resDoc.data.lname,
          resDoc.data.role
        );
        navigate("/manage/booking");
      }
    } catch (error) {
      // axios Handling Errors: https://axios-http.com/docs/handling_errors
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        setErrorState(error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        setErrorState(error.request);
        console.error(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log("Error", error.message);
        setErrorState(error);
        console.error(error);
      }
    }
  }

  return (
    <div className="container d-flex justify-content-center mt-5">
      <div className="card" style={{ width: "30rem" }}>
        <div className="card-header text-bg-primary">
          <div className="card-title h5">
            <BsPersonFillGear /> Staff Sign In
          </div>
        </div>

        <div className="card-body">
          <Alert
            show={!!errorState}
            variant="danger"
            onClose={() => setErrorState("")}
            dismissible
          >
            Error: {errorState}.
          </Alert>
          <div className="row mb-1">
            <div className="col-6 mb-1">
              <input
                className="form-control"
                required
                type="email"
                name="email"
                onChange={handleChange}
                placeholder="📧 email"
              />
            </div>
            <div className="col-6 mb-1">
              <input
                className="form-control"
                required
                type="password"
                name="password"
                pattern=".{6,}"
                placeholder="🔒Password (six+ characters)"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="card-footer text-end">
          <button className="btn btn-primary" type="button" onClick={signIn}>
            <h5>
              <BsFillPersonCheckFill /> Sign in
            </h5>
          </button>
          {forgotPassword ? (
            <StaffForgotPassword emailInput={loginStaff.email} />
          ) : (
            <div>
              Forgot your password?
              <button
                className="btn btn-link"
                onClick={() => setForgotPassword(true)}
              >
                Forgot password
              </button>
            </div>
          )}
          <div>
            <span>Don't have an account?</span>
            <button className="btn btn-link">
              <StaffSignUpModal />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
