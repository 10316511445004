import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { FaSave } from "react-icons/fa";

import AuthContext from "../../context/AuthContext";
import ListContext from "../../context/ListContext";
import { Alert } from "react-bootstrap";

export default function UserManageAdd() {
  const { token } = useContext(AuthContext);
  const { insuranceList } = useContext(ListContext);
  const [newUser, setNewUser] = useState();

  const [validPhone, setValidPhone] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [userReady, setUserReady] = useState(false);

  const [errorMsg, setErrorMsg] = useState();

  useEffect(() => {
    setUserReady(
      !!newUser &&
        !!newUser.fname &&
        !!newUser.lname &&
        isValidPhone(newUser.phone)
    );
  }, [newUser]);

  const handleChange = (event) => {
    setNewUser((prevEx) => {
      return {
        ...prevEx,
        [event.target.name]: event.target.value,
      };
    });
  };

  // function isValidMobile(mobileNr) {
  //   return /^04([ -]?[0-9]){8}$/.test(mobileNr);
  // }
  function isValidPhone(lineNr) {
    return /^(0[23478])([ -]?[0-9]){8}$/.test(lineNr);
  }
  const handlePhoneChange = (event) => {
    if (isValidPhone(event.target.value)) {
      setValidPhone(true);
    } else {
      setValidPhone(false);
    }
    handleChange(event);
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleEmailChange = (event) => {
    if (isValidEmail(event.target.value)) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
    handleChange(event);
  };

  async function addUser() {
    const authStr = "Bearer " + token;
    if (!newUser.insurance_id) {
      newUser.insurance_id = insuranceList.find(
        (insurance) => insurance.name === "Unspecified"
      )._id;
    }
    try {
      const resDoc = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/user/signUpByStaff",
        newUser,
        {
          headers: { Authorization: authStr },
        }
      );
      if (resDoc.status === 201) {
        // reload to clear form
        window.location.reload();
      } else if (resDoc.status === 208) {
        setErrorMsg(resDoc.data);
      } else {
        setErrorMsg(resDoc.data);
      }
    } catch (error) {
      // Axios Handling Errors: https://axios-http.com/docs/handling_errors
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        setErrorMsg(error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log("Error", error.message);
        console.error(error);
      }
      // console.log(error.config);
    }
  }

  return (
    <div className="row mt-2 bg-white">
      <Alert
        show={!!errorMsg}
        variant="danger"
        onClose={() => setErrorMsg()}
        dismissible
      >
        Error: {errorMsg}.
      </Alert>
      <div className="col-2">
        <input
          className="form-control"
          type="text"
          name="fname"
          placeholder="FirstName*"
          onChange={handleChange}
        />
      </div>
      <div className="col-1">
        <input
          className="form-control"
          type="text"
          name="lname"
          placeholder="LastName*"
          onChange={handleChange}
        />
      </div>

      <div className="col-2">
        <input
          type="text"
          className="form-control"
          name="phone"
          onChange={handlePhoneChange}
          placeholder="0450123456"
        />
        {!validPhone && (
          <small style={{ color: "red" }}>
            phone nr e.g., 0450123456, 0298691234
          </small>
        )}
      </div>
      <div className="col-2">
        <input
          type="email"
          className="form-control"
          name="email"
          onChange={handleEmailChange}
          placeholder="name@example.com"
        />
        {!validEmail && (
          <small style={{ color: "red" }}>enter valid email address</small>
        )}
      </div>

      <div className="col-1">
        <input
          className="form-control"
          required
          type="date"
          name="DOB"
          onChange={handleChange}
        />
      </div>
      <div className="col-1">manual</div>
      <div className="col-2">
        <select
          className="form-select"
          name="insurance_id"
          defaultValue=""
          onChange={handleChange}
        >
          <option disabled value="">
            insurance
          </option>
          {insuranceList.map((insurance) => (
            <option
              key={insurance._id}
              value={insurance._id}
              // selected={editUser.insurance_id._id === insurance._id}
            >
              {insurance.name}
            </option>
          ))}
        </select>
      </div>

      <div className="col-1">
        <button
          className="btn btn-link"
          disabled={!userReady}
          onClick={addUser}
        >
          <FaSave />
        </button>
      </div>
      <hr />
    </div>
  );
}
