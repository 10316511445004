import React, { useState, useContext } from "react";
import axios from "axios";
import { FaSave } from "react-icons/fa";

import AuthContext from "../../context/AuthContext";

export default function ManageInsuranceAdd() {
  const { token } = useContext(AuthContext);
  const [newInsurance, setNewInsurance] = useState();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewInsurance((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  async function addInsurance() {
    const authStr = "Bearer " + token;
    try {
      const resDoc = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/insurance/create",
        newInsurance,
        {
          headers: { Authorization: authStr },
        }
      );
      if (resDoc.status === 201) {
        // added - reload to clear form
        window.location.reload();
      } else {
        console.error(resDoc.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="row mt-2 bg-white">
      <div className="col-2">
        <input
          className="form-control"
          required
          type="text"
          name="name"
          placeholder="name"
          onChange={handleChange}
        />
      </div>
      <div className="col-8">
        <input
          className="form-control"
          type="text"
          name="note"
          placeholder="note"
          onChange={handleChange}
        />
      </div>
      <div className="col-1">active</div>
      <div className="col-1 text-center">
        <button className="btn btn-link" onClick={addInsurance}>
          <FaSave />
        </button>
      </div>
      <hr />
    </div>
  );
}
