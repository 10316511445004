import React, { useState, useEffect, useCallback, useRef } from "react";

import AuthContext from "./AuthContext";

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState();
  const [tokenExpireTime, setTokenExpireTime] = useState();
  const [user_id, setUser_id] = useState(null);
  const [fname, setFName] = useState();
  const [lname, setLName] = useState();
  const [role, setRole] = useState();

  /**
   * login function can be called from outside by AuthModal, login(token, user_id, user_name, user_role)
   * or from inside useEffect (when reload pages, dig variables) - login(LS_token, LS_uid, LS_uname, LS_role, LS_expireTime)
   */
  const login = useCallback((_token, _uId, _fname, _lname, _role, _expDate) => {
    const LOGIN_TOKEN_DURATION = 3600 * 1000 * 200; // 200 hours = 8.3 days
    // const LOGIN_TOKEN_DURATION = 30 * 1000; // 30 seconds
    setToken(_token);
    setUser_id(_uId);
    setFName(_fname);
    setLName(_lname);
    setRole(_role);
    const _tokenExpDate =
      _expDate || new Date(new Date().getTime() + LOGIN_TOKEN_DURATION);
    setTokenExpireTime(_tokenExpDate);
    // if login first time (_expDate NOT provided), _tokenExpDate is set to new Date()+1hr
    // if login from useEffect reload (_expDate provided), tokenExpDate is set to _expDate.

    localStorage.setItem(
      process.env.REACT_APP_LS_USER,
      JSON.stringify({
        user_id: _uId,
        token: _token,
        fname: _fname,
        lname: _lname,
        role: _role,
        expiration: _tokenExpDate.toISOString(),
      })
    );
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setTokenExpireTime(null);
    setUser_id(null);
    localStorage.removeItem(process.env.REACT_APP_LS_USER);
  }, []);

  const logoutTimerRef = useRef(0);
  /**
   * When token changes (due to login/logout), set timer to watch for token expire to logout
   */
  useEffect(() => {
    if (token && tokenExpireTime) {
      // if token exists, set timer to logout after token expires
      const remainingTime = tokenExpireTime.getTime() - new Date().getTime();
      logoutTimerRef.current = setTimeout(logout, remainingTime);
    } else {
      // if no token, clear timer
      clearTimeout(logoutTimerRef.current);
    }
  }, [token, logout, tokenExpireTime]);

  /**
   * When reloading pages, token and user states will be destroyed.
   * We need to reload token and user states from local storage
   * if there is a token in local storage, re-login with the stored token, other infor, and expiration time
   */
  useEffect(() => {
    const storedData = JSON.parse(
      localStorage.getItem(process.env.REACT_APP_LS_USER)
    );
    if (storedData && storedData.token) {
      if (new Date(storedData.expiration) > new Date()) {
        login(
          storedData.token,
          storedData.user_id,
          storedData.fname,
          storedData.lname,
          storedData.role,
          new Date(storedData.expiration)
        );
      } else {
        logout();
      }
    } else {
      logout();
    }
  }, [login, logout]);

  return (
    <AuthContext.Provider
      value={{
        token,
        user_id,
        fname,
        lname,
        role,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
