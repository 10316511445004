import axios from "axios";
import { useContext } from "react";
import { MdNotificationsActive } from "react-icons/md";

import AuthContext from "../../context/AuthContext";
import UserManageItemModal from "../UserManage/UserManageItemModal";

export default function UserNotifyItem({ index, user, setBookedUsers }) {
  const { token } = useContext(AuthContext);

  async function notifyUser(user_id) {
    const authStr = "Bearer " + token;
    try {
      const resDoc = await axios.patch(
        process.env.REACT_APP_BACKEND_URL + "/user/notify/" + user_id,
        {},
        {
          headers: { Authorization: authStr },
        }
      );
      if (resDoc.status === 200) {
        setBookedUsers(resDoc.data);
        // deleted - refresh page
        // window.location.reload();
      } else {
        console.error("not 200");
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <div className={index % 2 ? "mt-1" : "mt-1 bg-white"}>
        <div className="row">
          <div className="col-2">
            <UserManageItemModal
              user_id={user._id}
              userFName={user.fname}
              userLName={user.lname}
            />
          </div>
          <div className="col-1">{user.lname}</div>
          <div className="col-2">{user.phone}</div>
          <div className="col-2">{user.email}</div>
          <div className="col-1">
            {new Date(user.lastApptDate).toLocaleDateString()}
          </div>
          {/* <div className="col-1">{user.role}</div> */}
          <div className="col-1">
            {new Date(user.notifyDate).toLocaleDateString()}
          </div>
          <div className="col-1">
            <button
              className="btn btn-link"
              onClick={() => notifyUser(user._id)}
            >
              <MdNotificationsActive />
            </button>
          </div>
          <div className="col-1">{user.status}</div>
        </div>
      </div>
    </>
  );
}
