import React, { useState, useContext } from "react";
import axios from "axios";

import AuthContext from "../../context/AuthContext";
import { FaSave } from "react-icons/fa";

export default function ManageServiceEdit({ index, service }) {
  const { token } = useContext(AuthContext);
  const [editService, setEditService] = useState(service);

  const [iconImg, setIconImg] = useState(null);
  const handleSelectedIcon = (e) => {
    setIconImg(e.target.files[0]);
  };
  const [svcImg, setSvcImg] = useState();
  const handleSelectedImage = (e) => {
    setSvcImg(e.target.files[0]);
  };

  const handleChange = (event) => {
    setEditService((prevEx) => {
      return {
        ...prevEx,
        [event.target.name]: event.target.value,
      };
    });
  };

  async function saveUpdate() {
    const authStr = "Bearer " + token;

    const uploadForm = new FormData();
    uploadForm.append("iconImg", iconImg);
    uploadForm.append("svcImg", svcImg);
    // field name 'svcImg' must match backend post: upload.single('svcImg')
    for (const key in editService) {
      uploadForm.append(key, editService[key]);
    }

    try {
      const resDoc = await axios.put(
        process.env.REACT_APP_BACKEND_URL +
          "/service/update/" +
          editService._id,
        uploadForm,
        {
          headers: { Authorization: authStr },
        }
      );
      if (resDoc.status === 201) {
        // updated - refresh page
        window.location.reload(false);
      } else {
        console.error(resDoc.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className={index % 2 ? "form row mt-2 bg-white" : "form row mt-2"}>
      <div className="col-1">
        <input
          required
          className="form-control"
          type="text"
          name="name"
          defaultValue={editService.name}
          onChange={handleChange}
          placeholder="name"
        />
      </div>
      <div className="col-1">
        <input
          required
          className="form-control"
          type="number"
          name="seq"
          defaultValue={editService.seq}
          onChange={handleChange}
          placeholder="seq"
        />
      </div>
      <div className="col-1">
        <input
          type="file"
          className="form-control-file"
          onChange={handleSelectedIcon}
        />
      </div>
      <div className="col-2">
        <input
          type="file"
          className="form-control-file"
          onChange={handleSelectedImage}
        />
      </div>
      <div className="col-2">
        <textarea
          className="form-control"
          name="shortNote"
          rows="5"
          maxLength="200"
          defaultValue={editService.shortNote}
          onChange={handleChange}
          placeholder="short description (about 150 characters)"
        />
      </div>
      <div className="col-3">
        <textarea
          className="form-control"
          name="note"
          rows="5"
          defaultValue={editService.note}
          onChange={handleChange}
          placeholder="full description"
        />
      </div>
      <div className="col-1">
        <select
          className="form-select"
          defaultValue={editService.status}
          name="status"
          onChange={handleChange}
        >
          <option value="active">active</option>
          <option value="inactive">inactive</option>
        </select>
      </div>
      <div className="col-1 text-center">
        <button className="btn btn-link" onClick={saveUpdate}>
          <FaSave />
        </button>
      </div>
    </div>
  );
}
