import axios from "axios";
import { useContext } from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";

import AuthContext from "../../context/AuthContext";

export default function StaffManageItem({ index, staff, setEdit }) {
  const { token } = useContext(AuthContext);

  async function deleteStaff(staff_id) {
    const authStr = "Bearer " + token;
    try {
      const resDoc = await axios.delete(
        process.env.REACT_APP_BACKEND_URL + "/staff/delete/" + staff_id,
        {
          headers: { Authorization: authStr },
        }
      );
      if (resDoc.status === 201) {
        // deleted - refresh page
        window.location.reload();
      } else {
        console.error(resDoc.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <div className={index % 2 ? "mt-1" : "mt-1 bg-white"}>
        <div className="row">
          <div className="col-1">{staff.title}</div>
          <div className="col-1">{staff.fname}</div>
          <div className="col-1">{staff.lname}</div>
          <div className="col-2">{staff.position}</div>
          <div className="col-2">{staff.email}</div>
          <div className="col-1">{staff.phone}</div>
          <div className="col-1">{staff.seq}</div>
          <div className="col-1">{staff.status}</div>
          <div className="col-1">{staff.role}</div>

          <div className="col-1">
            <button className="btn btn-link" onClick={() => setEdit(staff._id)}>
              <FaEdit />
            </button>
            <button
              className="btn btn-link"
              onClick={() => deleteStaff(staff._id)}
              disabled={staff.status !== "disabled"}
            >
              <FaTrashAlt />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
