import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import { BsFillPersonPlusFill } from "react-icons/bs";

import ListContext from "../../context/ListContext";

export default function UserSignUpModal() {
  const { insuranceList } = useContext(ListContext);
  const [showRegModal, setShowRegModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState();

  const [newUser, setNewUser] = useState();

  const [mobNrError, setMobNrError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [regEnabled, setRegEnabled] = useState(false);
  const [regDone, setRegDone] = useState(false);

  useEffect(() => {
    setRegEnabled(
      newUser &&
        newUser.fname &&
        newUser.lname &&
        isMobileNr(newUser.phone) &&
        isValidEmail(newUser.email) &&
        newUser.password &&
        newUser.password2 &&
        newUser.DOB
    );
  }, [newUser]);

  const handleChange = (event) => {
    setNewUser((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  function isMobileNr(mobileNr) {
    return /^04(\s?[0-9]{2}\s?)([0-9]{3}\s?[0-9]{3}|[0-9]{2}\s?[0-9]{2}\s?[0-9]{2})$/.test(
      mobileNr
    );
  }
  const handleMobileChange = (event) => {
    if (isMobileNr(event.target.value)) {
      setMobNrError(false);
    } else {
      setMobNrError(true);
    }
    handleChange(event);
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleEmailChange = (event) => {
    if (isValidEmail(event.target.value)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
    handleChange(event);
  };

  function isValidPassword(password) {
    // six or more characters
    return /^.{6,}$/.test(password);
  }
  const handlePasswordChange = (event) => {
    if (isValidPassword(event.target.value)) {
      setPasswordError(false);
    } else {
      setPasswordError(true);
    }
    handleChange(event);
  };

  async function signUp() {
    if (newUser.password !== newUser.password2) {
      setErrorMsg("Confirm password doesn't match! Try again.");
      return;
    }
    delete newUser.password2;
    if (!newUser.insurance_id) {
      // if user didn't select insuranace, set to "Unspecified"
      newUser.insurance_id = insuranceList.find(
        (insurance) => insurance.name === "Unspecified"
      )._id;
    }
    try {
      const resDoc = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/user/signUp",
        newUser
      );
      if (resDoc.status === 201) {
        setRegDone(true);
      }
    } catch (error) {
      // axios Handling Errors: https://axios-http.com/docs/handling_errors
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        setErrorMsg(error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.error("Error", error.message);
        console.error(error);
      }
      console.error(error.config);
    }
  }

  return (
    <>
      <div onClick={() => setShowRegModal(true)}>Sign Up</div>

      <Modal show={showRegModal} onHide={() => setShowRegModal(false)}>
        <Modal.Header className="bg-warning" closeButton>
          <Modal.Title>
            <BsFillPersonPlusFill /> Create Account
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="card-body">
            <Alert
              show={!!errorMsg}
              variant="danger"
              onClose={() => setErrorMsg()}
              dismissible
            >
              Error: {errorMsg}.
            </Alert>
            <Alert show={regDone} variant="success">
              Account created. Please Log In.
            </Alert>
            <div className="row">
              <div className="col-6 mb-2">
                <input
                  className="form-control"
                  required
                  type="text"
                  name="fname"
                  onChange={handleChange}
                  placeholder="👤 First name*"
                />
              </div>
              <div className="col-6 mb-2">
                <input
                  className="form-control"
                  required
                  type="text"
                  name="lname"
                  onChange={handleChange}
                  placeholder="Last name 👤*"
                />
              </div>
              <div className="col-6">
                <input
                  className="form-control"
                  type="text"
                  name="phone"
                  onChange={handleMobileChange}
                  placeholder="☎︎ Mobile Number*"
                />
                {mobNrError && (
                  <small style={{ color: "red" }}>
                    enter valid mobie number:
                    <br />
                    e.g., 0450123456
                  </small>
                )}
              </div>

              <div className="col-6">
                <input
                  className="form-control"
                  required
                  type="email"
                  name="email"
                  onChange={handleEmailChange}
                  placeholder="📧 email*"
                />
                {emailError && (
                  <small style={{ color: "red" }}>
                    enter valid email address
                  </small>
                )}
              </div>
              <div className="col-12 mb-2">
                <small className="text-muted">
                  Mobile SMS and email used for appointment confirmation.
                </small>
              </div>
              <div className="col-6 mb-2">
                <input
                  className="form-control"
                  required
                  type="password"
                  name="password"
                  placeholder="🔒Password*"
                  onChange={handlePasswordChange}
                />
                {passwordError && (
                  <small style={{ color: "red" }}>
                    enter password with 6 or more characters
                  </small>
                )}
              </div>
              <div className="col-6 mb-2">
                <input
                  className="form-control"
                  required
                  type="password"
                  name="password2"
                  onChange={handleChange}
                  placeholder="🔒confirm password*"
                />
              </div>

              <div className="col-6 mb-3">
                <label>
                  <small> Date of Birth*:</small>
                  <input
                    className="form-control"
                    required
                    type="date"
                    name="DOB"
                    onChange={handleChange}
                  />
                </label>
              </div>
              <div className="col-6">
                <label>
                  <small>Insurance:</small>
                  <select
                    className="form-select"
                    name="insurance_id"
                    defaultValue={
                      insuranceList.find(
                        (insurance) => insurance.name === "Unspecified"
                      )._id
                    }
                    // defaultValue={updateUser.insurance_id}
                    onChange={handleChange}
                  >
                    {insuranceList.map((insurance) => (
                      <option
                        key={insurance._id}
                        value={insurance._id}
                        // selected={editUser.insurance_id._id === insurance._id}
                      >
                        {insurance.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="card-footer">
            {regDone ? (
              <button
                className="btn btn-success"
                onClick={() => setShowRegModal(false)}
              >
                Return
              </button>
            ) : (
              <button
                className="btn btn-warning"
                disabled={!regEnabled}
                onClick={signUp}
              >
                <h5>
                  <BsFillPersonPlusFill /> Register
                </h5>
              </button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
