import React, { useState, useContext } from "react";
import axios from "axios";

import AuthContext from "../../context/AuthContext";
import { FaSave } from "react-icons/fa";

export default function ManageInsuranceEdit({ index, insurance }) {
  const { token } = useContext(AuthContext);
  const [editInsurance, setEditInsurance] = useState(insurance);

  const handleChange = (event) => {
    setEditInsurance((prevEx) => {
      return {
        ...prevEx,
        [event.target.name]: event.target.value,
      };
    });
  };

  async function saveUpdate() {
    const authStr = "Bearer " + token;
    try {
      const resDoc = await axios.put(
        process.env.REACT_APP_BACKEND_URL +
          "/insurance/update/" +
          editInsurance._id,
        editInsurance,
        {
          headers: { Authorization: authStr },
        }
      );
      if (resDoc.status === 201) {
        // updated - refresh page
        window.location.reload(false);
      } else {
        console.error(resDoc.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className={index % 2 ? "form row mt-2 bg-white" : "form row mt-2"}>
      <div className="col-2">
        <input
          required
          className="form-control"
          type="text"
          name="name"
          defaultValue={editInsurance.name}
          onChange={handleChange}
          placeholder="name"
        />
      </div>
      <div className="col-8">
        <input
          className="form-control"
          type="text"
          name="note"
          defaultValue={editInsurance.note}
          onChange={handleChange}
          placeholder="note"
        />
      </div>
      <div className="col-1">
        <select
          className="form-select"
          defaultValue={editInsurance.status}
          name="status"
          onChange={handleChange}
        >
          <option value="active">active</option>
          <option value="inactive">inactive</option>
        </select>
      </div>
      <div className="col-1 text-center">
        <button className="btn btn-link" onClick={saveUpdate}>
          <FaSave />
        </button>
      </div>
    </div>
  );
}
