import { useContext } from "react";
import axios from "axios";
import { FaEdit, FaTrashAlt } from "react-icons/fa";

import AuthContext from "../../context/AuthContext";

export default function ManageReasonItem({ index, reason, setEdit }) {
  const { token } = useContext(AuthContext);

  async function deleteReason(reason_id) {
    const authStr = "Bearer " + token;
    try {
      const resDoc = await axios.delete(
        process.env.REACT_APP_BACKEND_URL + "/reason/delete/" + reason_id,
        {
          headers: { Authorization: authStr },
        }
      );
      if (resDoc.status === 201) {
        // deleted - refresh page
        window.location.reload();
      } else {
        console.error(resDoc.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className={index % 2 ? "row mt-2 bg-white" : "row mt-2"}>
      <div className="col-2">{reason.name}</div>
      <div className="col-1">{reason.seq}</div>
      <div className="col-4">{reason.note}</div>
      <div className="col-1">{reason.status}</div>

      <div className="col-1 text-center">
        <button className="btn btn-link" onClick={() => setEdit(reason._id)}>
          <FaEdit />
        </button>
        <button
          className="btn btn-link"
          disabled={reason.status !== "inactive"}
          onClick={() => deleteReason(reason._id)}
        >
          <FaTrashAlt />
        </button>
      </div>
    </div>
  );
}
