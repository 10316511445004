import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import ServiceImg from "./edc-title-service.jpg";
import ListContext from "../../context/ListContext";
import Title from "../MyNavbar/Title";
import NoGapButton from "../Home/NoGapButton";

export default function ServiceDetail() {
  const { serviceName } = useParams();
  const { serviceList } = useContext(ListContext);

  const [selService, setSelService] = useState();

  useEffect(() => {
    const _serv = serviceList.find(
      (serv) => serv.name.replace(/\s/g, "") === serviceName
    );
    setSelService({ ..._serv });
  }, [setSelService, serviceList, serviceName]);

  return (
    <>
      {selService && (
        <>
          <Title
            bg_img={ServiceImg}
            title={"Our Service - " + selService.name}
          />
          <div className="container">
            <div className="row m-3">
              <div className="col-lg-6">
                <img
                  className="img-fluid"
                  src={`${process.env.REACT_APP_ASSET_URL}/${selService.imgPath}`}
                  alt="service img"
                />
              </div>
              <div className="col-lg-6">
                <h3>
                  <img
                    src={
                      process.env.REACT_APP_ASSET_URL +
                      "/" +
                      selService.iconPath
                    }
                    width="30px"
                    alt="service icon"
                  />{" "}
                  {selService.name}
                </h3>
                <div dangerouslySetInnerHTML={{ __html: selService.note }} />
                <div className="text-end">
                  <small>
                    Dental Icon by{" "}
                    <a
                      // className="text-white"
                      target="_blank"
                      href="https://icons8.com"
                      rel="noreferrer"
                    >
                      Icons8
                    </a>
                  </small>
                </div>
              </div>
            </div>
          </div>
          <NoGapButton />
        </>
      )}
    </>
  );
}
