import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { Calendar, dateFnsLocalizer, Views } from "react-big-calendar";
import { format, parse, startOfWeek, getDay } from "date-fns";
import "react-big-calendar/lib/css/react-big-calendar.css";

import "./CreateSchedule.css";
import AuthContext from "../../context/AuthContext";
import SaveTempModal from "./SaveTempModal";
import CreateScheduleModal from "./CreateScheduleModal";

const locales = {
  "en-AU": require("date-fns/locale/en-AU"),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
  getDay,
  locales,
});

export default function CreateSchedule() {
  const { token, fname, lname } = useContext(AuthContext);
  const [slotList, setSlotList] = useState([]);
  const [startDate, setStartDate] = useState(nextMonday(new Date()));

  // load the last appointment date and set the following Monday as startDate
  const getLastApptDate = useCallback(async () => {
    if (!token) return; // skip if token is null at initial refresh
    // get the last appointment date and set the following Monday as startDate
    try {
      const authStr = "Bearer " + token;
      const resDoc = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/appt/lastdate",
        {
          headers: { Authorization: authStr },
        }
      );
      if (resDoc.status === 200) {
        const lastDate = new Date(resDoc.data);
        setStartDate(nextMonday(lastDate));
      }
    } catch (error) {
      // if cannot load, leave the startDate to default
      console.log("last date error: catch error");
    }
  }, [token]);
  useEffect(() => {
    getLastApptDate();
  }, [getLastApptDate]);

  /**
   * Load template and display the slots in the Create Schedule Calendar
   * Note: template slots may have old dates associated with them
   *       need to assign new dates (in the calendar week) to the template slot dates,
   *       so that we can generate appointments from the new template slots
   * When saving template, the template slots will be saved with the new dates.
   */
  useEffect(() => {
    const getTemp = async () => {
      if (!token) return; // skip if token is null at initial refresh
      // get booking template for this dentist
      try {
        const authStr = "Bearer " + token;
        const resDoc = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/staff/dentist/book-temp",
          {
            headers: { Authorization: authStr },
          }
        );
        if (resDoc.status === 200) {
          // convert the template (maybe with old dates) into slotList (with dates in the current calendar)
          const _slotList = resDoc.data.map((slot) => {
            // 1. find out slot.start, slotStart, day difference from Monday,
            //    e.g. if slot.start is Wed (3), diff=slotStart.getDay()-1=2, or diff=(slotStart.getDay()-1+7)%7=2
            //    special case: if slot.start is Sun (0), (slotStart.getDay()-1+7)%7=6
            const slotStart = new Date(slot.start);
            const diff = (slotStart.getDay() - 1 + 7) % 7; // get the diff to Monday, because Sunday.getDay()=0, we have to -1+7
            // 2. newSlotStart is assign to the same week day in the current calendar week by newAddDays(startDay,diff)
            //    and set to the slotStart's hour and minute
            let newSlotStart = new Date(
              newAddDays(startDate, diff).setHours(
                slotStart.getHours(),
                slotStart.getMinutes(),
                0,
                0
              )
            );
            // 3. newSlotEnd is assigned similarly
            const slotEnd = new Date(slot.end);
            let newSlotEnd = new Date(
              newAddDays(startDate, diff).setHours(
                slotEnd.getHours(),
                slotEnd.getMinutes(),
                0,
                0
              )
            );
            // 4. return the new slot
            return {
              title: fname[0] + lname[0] + ":Template",
              start: newSlotStart,
              end: newSlotEnd,
            };
          });
          setSlotList([..._slotList]);
        }
      } catch (error) {
        console.log("no template");
      }
    };
    getTemp();
  }, [token, setSlotList, startDate, fname, lname]);

  // return the following Monday even if the current date is a Monday:
  // https://stackoverflow.com/questions/33078406/getting-the-date-of-next-monday
  function nextMonday(_date) {
    let d = new Date(_date);
    d.setDate(d.getDate() + ((1 + 7 - d.getDay()) % 7 || 7));
    return d;
  }

  // Return a date object by adding "days" to "startDate"
  function newAddDays(_startDate, _days) {
    let result = new Date(_startDate);
    result.setDate(result.getDate() + _days);
    return result;
  }

  // Dentist create schedule (slot by slot) for the week based on the set template: slotList
  const createSched = () => {
    const authStr = "Bearer " + token;
    slotList.map(async (slot) => {
      try {
        await axios.post(
          process.env.REACT_APP_BACKEND_URL + "/appt/dentist/create",
          slot,
          {
            headers: { Authorization: authStr },
          }
        );
      } catch (error) {
        // possibly not authorised.
        console.error(error);
      }
    });
    // note, we are not expecting any returns from the create endpoint.
    // move to the following week after creating schedule for the current week
    getLastApptDate();
  };

  // Generate slots from slotList
  const saveTemp = async () => {
    const authStr = "Bearer " + token;
    try {
      const resDoc = await axios.put(
        process.env.REACT_APP_BACKEND_URL + "/staff/dentist/save-temp",
        slotList,
        {
          headers: { Authorization: authStr },
        }
      );
      if (resDoc.status === 201) {
        window.location.reload(false);
      } else {
        console.error("error creating appointment");
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  // click on a scheduled slot - delete this schedule
  const handleSelectEvent = useCallback(
    (event) => {
      setSlotList([...slotList.filter((e) => e.start !== event.start)]);
    },
    [slotList]
  );

  // click on an empty slot: creat a schedule with start/end of that slot
  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      setSlotList([
        ...slotList,
        { title: fname[0] + lname[0] + ":NewSlot", start, end },
      ]);
    },
    [slotList, fname, lname]
  );

  // define event styles based on status
  // styles defined in .css file
  const eventStyleGetter = useCallback((event, start, end, isSelected) => {
    return { className: "dentist-slot" };
  }, []);

  // constrol texts displayed on the event
  // timeslot label was hidden by .css style
  const components = useMemo(
    () => ({
      event: (props) => {
        return (
          <div>
            {props.title}
            {/* {props.event.username && " by " + props.event.username} */}
          </div>
        );
      },
    }),
    []
  );

  return (
    <div className="container-fluid">
      <h1 className="text-center">
        Create Schedule for Dentist: {fname} {lname}
      </h1>

      <div className="row">
        <div className="col-4">
          <SaveTempModal saveTemp={saveTemp} />
          <p>Save for future use.</p>
        </div>
        <div className="col-4">
          <CreateScheduleModal
            startDate={startDate}
            endDate={newAddDays(startDate, 6)}
            createSched={createSched}
          />
          <h5>
            {startDate.toDateString().substring(0, 10)} -{" "}
            {newAddDays(startDate, 6).toDateString().substring(0, 10)}
          </h5>
        </div>
        <div className="col-4">
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => setStartDate(newAddDays(startDate, 7))}
          >
            skip to next week
          </button>
          <p>I want to take this week OFF.</p>
        </div>
      </div>

      <Calendar
        defaultDate={startDate}
        date={startDate}
        onNavigate={(date) => {
          setStartDate(date);
        }}
        localizer={localizer}
        events={slotList}
        toolbar={false}
        // backgroundEvents={bgEvents}
        min={new Date(2020, 1, 0, 8)}
        max={new Date(2020, 1, 0, 22)}
        startAccessor="start"
        endAccessor="end"
        defaultView={Views.WEEK}
        views={[Views.WEEK]}
        step={30} // 15 min/slot
        timeslots={2} // 4 slots/section
        selectable
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        eventPropGetter={eventStyleGetter}
        components={components}
        style={{
          height: 1000,
        }}
      />
    </div>
  );
}
