import React, { useState, useEffect } from "react";
import axios from "axios";

import ListContext from "./ListContext";

const ListProvider = ({ children }) => {
  const [serviceList, setServiceList] = useState([]);
  useEffect(() => {
    const fetchService = async () => {
      try {
        const resDoc = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/service/active"
        );
        if (resDoc.data.length > 0) {
          setServiceList([...resDoc.data]);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchService();
  }, []);

  const [reasonList, setReasonList] = useState([]);
  useEffect(() => {
    const fetchReason = async () => {
      try {
        const resDoc = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/reason/active"
        );
        if (resDoc.data.length > 0) {
          setReasonList([...resDoc.data]);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchReason();
  }, []);

  const [insuranceList, setInsuranceList] = useState([]);
  useEffect(() => {
    const fetchInsurance = async () => {
      try {
        const resDoc = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/insurance/active"
        );
        if (resDoc.data.length > 0) {
          setInsuranceList([...resDoc.data]);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchInsurance();
  }, []);

  // load dentists for the Dentist Dropdown menu
  const [dentistList, setDentistList] = useState([]);
  useEffect(() => {
    const fetchDentists = async () => {
      try {
        const resDoc = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/staff/dentist/active"
        );
        if (resDoc.data.length > 0) {
          setDentistList(resDoc.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchDentists();
  }, []);

  // load dentists for the Dentist Dropdown menu
  const [staffList, setStaffList] = useState([]);
  useEffect(() => {
    const fetchStaff = async () => {
      try {
        const resDoc = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/staff/info"
        );
        if (resDoc.data.length > 0) {
          setStaffList(resDoc.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchStaff();
  }, []);

  return (
    <ListContext.Provider
      value={{
        insuranceList,
        reasonList,
        serviceList,
        dentistList,
        staffList,
      }}
    >
      {children}
    </ListContext.Provider>
  );
};

export default ListProvider;
