import { BiMessageDetail } from "react-icons/bi";
import ContactUsBody from "../ContactUs/ContactUsBody";
import ServiceBody from "../Service/ServiceBody";
import "./Home.css";
import HomeVideo from "./edc-home-720p.mp4";
import HomeVideoMobile from "./edc-home1x1-720p.mp4";
import AboutUsIcon from "./dentist.png";
import ServicesIcon from "./dental-care.png";
import AboutUsBody from "../AboutUs/AboutUsBody";
import NoGapButton from "./NoGapButton";

export default function Home() {
  return (
    <div>
      <NoGapButton />
      <section id="video-container">
        <div className="d-none d-lg-block">
          <video autoPlay muted playsInline>
            <source src={HomeVideo} type="video/mp4" />
          </video>
          <div id="text-on-video">
            <h3 className="text-center">
              Welcome to{" "}
              <img
                src="/logos/edc-logo-hz-light.png"
                alt="logo"
                width="150px"
              />
              {/* {process.env.REACT_APP_COMPANY} */}
            </h3>
            <p className="container">
              Established in 1993, Epping Dental Centre has been creating and
              maintaining beautiful smiles in the local community for over 30
              years. Our team of experienced dental professionals are committed
              to helping you achieve your dental goals.{" "}
              <a
                style={{ color: "inherit" }}
                href={"tel:" + process.env.REACT_APP_PHONE}
              >
                Call us
              </a>{" "}
              today or{" "}
              <a style={{ color: "inherit" }} href="/book-now">
                Book Now
              </a>
              .
            </p>
          </div>
        </div>
        <div className="d-block d-lg-none">
          <video autoPlay muted playsInline>
            <source src={HomeVideoMobile} type="video/mp4" />
          </video>
          <div id="text-on-video">
            <h3 className="text-center">
              <img
                src="/logos/edc-logo-hz-light.png"
                alt="logo"
                width="150px"
              />
            </h3>
            <p className="text-center">
              <a
                style={{ color: "inherit" }}
                href={"tel:" + process.env.REACT_APP_PHONE}
              >
                Call us
              </a>{" "}
              today or{" "}
              <a style={{ color: "inherit" }} href="/book-now">
                Book Now
              </a>
              .
            </p>
          </div>
        </div>
      </section>

      <section className="text-success">
        <div className="container" id="home-intro">
          <h3 className="text-center pt-2">
            <img src={AboutUsIcon} alt="aboutus" width="33px" /> About Us
          </h3>
          <AboutUsBody />
        </div>
      </section>

      <section id="section-service">
        <h3 className="text-center py-3">
          <img src={ServicesIcon} alt="service" width="33px" /> Our Services
        </h3>
        <div className="container">
          <ServiceBody />
        </div>
      </section>

      <section className="text-success">
        <h3 className="text-center text-success pt-3">
          <BiMessageDetail /> Contact Us
        </h3>
        <ContactUsBody />
      </section>
    </div>
  );
}
