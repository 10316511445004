import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function SaveTempModal({ saveTemp }) {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button variant="primary" onClick={() => setShow(true)}>
        Save Template
      </Button>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Save Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Would you like to save the template for future use?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              saveTemp();
              setShow(false);
            }}
          >
            Save Template
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
