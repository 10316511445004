import React, { useContext } from "react";
import AuthContext from "../../context/AuthContext";

export default function Title({ bg_img, name, title, text }) {
  const { token, fname, lname } = useContext(AuthContext);

  const titleStyle = {
    backgroundColor: "gray",
    backgroundImage: "url(" + bg_img + ")",
    backgroundSize: "auto 100%",
    margin: 0,
  };

  return (
    <div>
      <div
        className="text-white d-none d-md-block py-3 position-relative"
        style={titleStyle}
      >
        <h1 className="text-center">{title}</h1>
        {name}
        {text && <h5 className="text-left">{text}</h5>}
        <div
          style={{
            position: "absolute",
            top: "5px",
            right: "10px",
          }}
        >
          {token && (
            <div>
              {fname} {lname}
            </div>
          )}
        </div>
      </div>

      {/* mobile phone title */}
      <div
        className="text-white d-md-none py-3 position-relative"
        style={titleStyle}
      >
        <h5 className="text-center">
          {title}
          {name}
        </h5>
        {text && <h5 className="text-left">{text}</h5>}
        <div
          style={{
            position: "absolute",
            top: "2px",
            right: "2px",
          }}
        >
          {token && (
            <div>
              {fname[0]}.{lname}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
