import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BiSolidShieldPlus, BiSolidTime } from "react-icons/bi";
import {
  FaClinicMedical,
  FaNotesMedical,
  FaRegCalendarCheck,
  FaUserNurse,
} from "react-icons/fa";

import AuthContext from "../../context/AuthContext";
import { BsPersonFillAdd, BsTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import UserSignInModal from "../User/UserSignInModal";
import UserSignUpModal from "../User/UserSignUpModal";
import ListContext from "../../context/ListContext";
import { Alert } from "react-bootstrap";

export default function BookNowAppt() {
  const { token } = useContext(AuthContext);
  const { insuranceList, reasonList } = useContext(ListContext);
  const { appt_id, reason_id } = useParams();
  const navigate = useNavigate();

  const [selAppt, setSelAppt] = useState();
  const [loginUser, setLoginUser] = useState();

  const [option, setOption] = useState("myself");
  const [patient, setPatient] = useState();

  const [errorMsg, setErrorMsg] = useState();
  const [bookEnabled, setBookEnabled] = useState(false);

  // load the selected appointment
  useEffect(() => {
    const fetchAppt = async () => {
      try {
        const resDoc = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/appt/vacant_id/" + appt_id
        );
        // console.log(resDoc.data);
        if (resDoc.status === 200) {
          setSelAppt(resDoc.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchAppt();
  }, [appt_id]);

  // load the login user, if token exists
  useEffect(() => {
    const fetchUser = async () => {
      if (!token) return;
      const authStr = "Bearer " + token;
      try {
        const resDoc = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/user/getSelf",
          {
            headers: { Authorization: authStr },
          }
        );
        if (resDoc.status === 200) {
          setLoginUser(resDoc.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchUser();
  }, [token]);

  // set default patient as login user, if loginUser is available
  useEffect(() => {
    if (loginUser) {
      setPatient({
        fname: loginUser.fname,
        lname: loginUser.lname,
        DOB: loginUser.DOB,
      });
    }
  }, [loginUser]);

  // enable book button when patient data are complete
  useEffect(() => {
    setBookEnabled(patient && patient.fname && patient.lname && patient.DOB);
  }, [patient]);

  const handleChange = (event) => {
    setPatient((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleOptionChange = (changeEvent) => {
    const newOption = changeEvent.target.value;
    setOption(newOption);
    if (newOption === "other") {
      setPatient();
    } else {
      setPatient({
        fname: loginUser.fname,
        lname: loginUser.lname,
        DOB: loginUser.DOB,
        note: "",
      });
    }
  };

  const bookAppt = async () => {
    const authStr = "Bearer " + token;
    try {
      const resDoc = await axios.put(
        process.env.REACT_APP_BACKEND_URL + `/appt/user/book/${appt_id}`,
        {
          reason_id,
          patient,
        },
        {
          headers: { Authorization: authStr },
        }
      );
      if (resDoc.status === 201) {
        // confirming appointment has been booked
        // reload appointments to force a re-render of the page.
        navigate("/user/dashboard");
      } else {
        // appointment not vacant, please reselect
        setErrorMsg("not successful");
      }
    } catch (error) {
      // Axios Handling Errors: https://axios-http.com/docs/handling_errors
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        setErrorMsg(error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log("Error", error.message);
        console.error(error);
      }
      // console.log(error.config);
    }
    // window.alert(event.appt_id);
  };

  return (
    <div className="container">
      <h5>Book Appointment</h5>
      {selAppt ? (
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="card border-success shadow">
              <div className="card-header text-bg-success fw-bold">
                Appointment on{" "}
                {new Date(selAppt.start).toDateString().substring(0, 10)}
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-1 mb-1">
                    <BiSolidTime />
                  </div>
                  <div className="col-11">
                    {new Date(selAppt.start)
                      .toLocaleTimeString("en-AU", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })
                      .replace(/ /, "")}
                    -
                    {new Date(selAppt.end)
                      .toLocaleTimeString("en-AU", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })
                      .replace(/ /, "")}
                  </div>
                  <div className="col-1 mb-1">
                    <FaUserNurse />
                  </div>
                  <div className="col-11">
                    Dr {selAppt.dentist_id.fname} {selAppt.dentist_id.lname}
                  </div>
                  <div className="col-1 mb-1">
                    <FaClinicMedical />
                  </div>
                  <div className="col-11">{process.env.REACT_APP_COMPANY}</div>
                  <div className="col-1 mb-1">
                    <FaNotesMedical />
                  </div>
                  <div className="col-11">
                    {reasonList.length > 0 &&
                      reasonList.find((reason) => reason._id === reason_id)
                        .name}
                  </div>
                </div>
              </div>

              <div className="card-header text-bg-primary fw-bold">
                Patient Details
              </div>

              <div className="card-body">
                {loginUser ? (
                  <div className="row">
                    <div className="col-1 mb-1">
                      <BsPersonFillAdd />
                    </div>
                    <div className="col-11">
                      {loginUser.fname} {loginUser.lname}
                    </div>
                    <div className="col-1 mb-1">
                      <MdEmail />
                    </div>
                    <div className="col-11">{loginUser.email}</div>
                    <div className="col-1 mb-1">
                      <BsTelephoneFill />
                    </div>
                    <div className="col-11">{loginUser.phone}</div>
                    <div className="col-1 mb-1">
                      <BiSolidShieldPlus />
                    </div>
                    <div className="col-11">
                      {insuranceList.length > 0 &&
                        insuranceList.find(
                          (insurance) =>
                            insurance._id === loginUser.insurance_id
                        ) &&
                        insuranceList.find(
                          (insurance) =>
                            insurance._id === loginUser.insurance_id
                        ).name}
                    </div>

                    <div className="col-12">
                      <hr />
                    </div>

                    <div className="col-3 fw-bold">Patient:</div>
                    <div className="col-3 fw-bold">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="myself"
                          value="myself"
                          checked={option === "myself"}
                          onChange={handleOptionChange}
                        />
                        <label className="form-check-label" htmlFor="myself">
                          Myself
                        </label>
                      </div>
                    </div>
                    <div className="col-6 fw-bold">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="other"
                          value="other"
                          checked={option === "other"}
                          onChange={handleOptionChange}
                        />
                        <label className="form-check-label" htmlFor="other">
                          SomeoneElse
                        </label>
                      </div>
                    </div>

                    {option === "myself" ? (
                      <>
                        <div className="col-3 text-end mb-1">Name:</div>
                        <div className="col-9">
                          {patient && patient.fname + " " + patient.lname}
                        </div>
                        <div className="col-3 text-end mb-1">DOB:</div>
                        <div className="col-9">
                          {patient &&
                            new Date(patient.DOB).toLocaleDateString()}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-3 text-end mt-4">Name*:</div>
                        <div className="col-9">
                          <div className="text-muted">
                            One patient per appointment.
                          </div>
                          <input
                            className="form-control mb-1"
                            required
                            type="text"
                            name="fname"
                            onChange={handleChange}
                            placeholder="👤 Patient first name*"
                          />
                          <input
                            className="form-control mb-1"
                            required
                            type="text"
                            name="lname"
                            onChange={handleChange}
                            placeholder="Patient last name 👤*"
                          />
                        </div>
                        <div className="col-3 text-end">DOB*:</div>
                        <div className="col-9">
                          <div className="col-lg-6">
                            <input
                              className="form-control mb-1"
                              required
                              type="date"
                              name="DOB"
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <div className="col-3 text-end"> note:</div>
                    <div className="col-9">
                      <input
                        className="form-control"
                        type="text"
                        name="note"
                        onChange={handleChange}
                        placeholder="optional note"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-6 fw-bold">
                      Return Patient
                      <br />
                      <button className="btn btn-primary fw-bold">
                        <UserSignInModal />
                      </button>
                    </div>
                    <div className="col-6 fw-bold">
                      New Patient
                      <br />
                      <button className="btn btn-warning fw-bold">
                        <UserSignUpModal />
                      </button>
                    </div>
                  </div>
                )}
              </div>

              <Alert
                show={!!errorMsg}
                variant="danger"
                onClose={() => setErrorMsg("")}
                dismissible
              >
                Error: {errorMsg}.
                <hr />
                <div className="d-flex justify-content-end">
                  <a href="/book-now" className="btn btn-primary">
                    Go back to Reselect
                  </a>
                </div>
              </Alert>
              <div className="card-footer text-end">
                <button
                  className="btn btn-success"
                  disabled={!bookEnabled}
                  onClick={bookAppt}
                >
                  Book Appointment
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          Appointment Taken, please reselect{" "}
          <a
            type="button"
            className="btn btn-success text-white fw-bold"
            href="/book-now"
          >
            <FaRegCalendarCheck /> Book Now
          </a>
        </div>
      )}
    </div>
  );
}
