import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import NavbarToggle from "react-bootstrap/esm/NavbarToggle";
import Nav from "react-bootstrap/Nav";
import Dropdown from "react-bootstrap/Dropdown";
import { MdSettings } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";
import { BsFillPersonCheckFill, BsPersonFillGear } from "react-icons/bs";

import "./MyNavbar.css";
import AuthContext from "../../context/AuthContext";
import UserSignInModal from "../User/UserSignInModal";
import ListContext from "../../context/ListContext";
import { FaCalendarCheck, FaMobileAlt, FaSignOutAlt } from "react-icons/fa";

export default function MyNavbar() {
  const navigate = useNavigate();
  const { token, fname, lname, role, logout } = useContext(AuthContext);
  const { serviceList } = useContext(ListContext);
  // highlight active navbar link,
  // ref: https://stackoverflow.com/questions/70586714/how-to-make-an-active-navbar-link-when-it-shows-its-page-using-react-bootstrap
  const location = useLocation();
  const [url, setUrl] = useState("");

  const [showServiceMenu, setShowServiceMenu] = useState(false);
  const [showAdminSetting, setShowAdminSetting] = useState(false);
  const [showDentistSetting, setShowDentistSetting] = useState(false);

  // get current url of the page - to highlight corresponding Navbar
  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  // shrink navbar brand img when scroll down
  useEffect(() => {
    const handler = () => {
      // shrink navbar brand img when scroll down
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        document.getElementById("navbar-brand-img").style.width = "40px";
        document.getElementById("navbar-brand-filler").style.width = "40px";
        return true;
      }
      // restore navbar brand img when scroll back to top
      if (
        document.body.scrollTop < 80 &&
        document.documentElement.scrollTop < 80
      ) {
        document.getElementById("navbar-brand-img").style.width = "120px";
        document.getElementById("navbar-brand-filler").style.width = "120px";
        return false;
      }
    };
    window.addEventListener("scroll", handler);
    return () => window.removeEventListener("scroll", handler);
  }, []);

  function signOut() {
    logout();
    navigate("/");
  }

  return (
    <>
      <div
        className="d-none d-lg-block bg-success text-white"
        id="my-navbar-header"
      >
        {/* top header also disappear when navbar collapse - not lg */}
        <h1>{process.env.REACT_APP_COMPANY}</h1>
      </div>
      <Navbar
        className="p-0"
        collapseOnSelect
        variant="dark"
        bg="success"
        expand="lg"
        sticky="top"
      >
        <NavbarToggle aria-controls="responsive-navbar-nav" />

        <Navbar.Brand href="/">
          <img
            src={"/logos/edc-logo-mark-light.png"}
            alt="logo"
            id="navbar-brand-img"
            className="d-lg-inline-block d-none"
          />
          <img
            src={"/logos/edc-logo-mark-light.png"}
            alt="edc logo"
            id="navbar-brand-img-sm"
            className="d-inline-block d-lg-none"
          />
        </Navbar.Brand>

        <Navbar.Collapse>
          <Nav id="navbar-brand-filler" className="d-lg-inline-block d-none" />
          <Nav>
            <Nav.Link
              id="mynav-link"
              className={url === "/" ? "active" : ""}
              href="/"
            >
              Home
            </Nav.Link>
            <Nav.Link
              id="mynav-link"
              active={url.startsWith("/aboutus") ? true : false}
              href="/aboutus"
            >
              About Us
            </Nav.Link>

            <Dropdown
              onMouseEnter={() => setShowServiceMenu(true)}
              onMouseLeave={() => setShowServiceMenu(false)}
            >
              <Dropdown.Toggle
                as={Nav.Link}
                // href="/services"
                id="mynav-link"
                active={url.startsWith("/service") ? true : false}
              >
                Services
              </Dropdown.Toggle>
              <Dropdown.Menu id="dropdown-menu" show={showServiceMenu}>
                {serviceList.map((svc) => {
                  return (
                    <Dropdown.Item
                      key={svc._id}
                      href={`/service/${svc.name.replace(/\s/g, "")}`}
                    >
                      {svc.name}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>

        <Nav>
          {(!token || (token && role === "user")) && (
            <Nav.Link
              type="button"
              className={
                "nav-link btn btn-info text-white fw-bold" +
                (url === "/book-now" ? " active" : "")
              }
              href="/book-now"
            >
              <FaCalendarCheck /> Book Now
            </Nav.Link>
          )}
        </Nav>

        <Navbar.Collapse>
          <Nav className="ms-auto">
            <Nav.Link
              id="mynav-link"
              className={url === "/contactus" ? " active" : ""}
              href="/contactus"
            >
              Contact Us
            </Nav.Link>
            <Nav.Link
              id="mynav-link"
              className="nav-link"
              href={"tel:" + process.env.REACT_APP_PHONE}
            >
              <FiPhoneCall /> {process.env.REACT_APP_PHONE}
            </Nav.Link>
            <Nav.Link
              id="mynav-link"
              className="nav-link"
              href={"tel:" + process.env.REACT_APP_MOBILE}
            >
              <FaMobileAlt /> {process.env.REACT_APP_MOBILE}
            </Nav.Link>
            {token && role === "admin" && (
              <Dropdown
                onMouseEnter={() => setShowAdminSetting(true)}
                onMouseLeave={() => setShowAdminSetting(false)}
              >
                <Dropdown.Toggle
                  as={Nav.Link}
                  id="mynav-link"
                  active={url.startsWith("/manage") ? true : false}
                >
                  Manage <MdSettings />
                </Dropdown.Toggle>
                <Dropdown.Menu id="dropdown-menu" show={showAdminSetting}>
                  <Dropdown.Item href="/manage/booking">
                    Manage Booking
                  </Dropdown.Item>
                  <Dropdown.Item href="/manage/notifyuser">
                    Manage Notification
                  </Dropdown.Item>
                  <Dropdown.Item href="/manage/staff">
                    Manage Staff
                  </Dropdown.Item>
                  <Dropdown.Item href="/manage/user">Manage User</Dropdown.Item>
                  <Dropdown.Item href="/manage/reason">
                    Manage Reason
                  </Dropdown.Item>
                  <Dropdown.Item href="/manage/insurance">
                    Manage Insurance
                  </Dropdown.Item>
                  <Dropdown.Item href="/manage/service">
                    Manage Service
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
            {token && role === "staff" && (
              <Dropdown
                onMouseEnter={() => setShowAdminSetting(true)}
                onMouseLeave={() => setShowAdminSetting(false)}
              >
                <Dropdown.Toggle
                  as={Nav.Link}
                  active={url.startsWith("/manage") ? true : false}
                >
                  Manage <MdSettings />
                </Dropdown.Toggle>
                <Dropdown.Menu id="dropdown-menu" show={showAdminSetting}>
                  <Dropdown.Item href="/manage/booking">
                    Manage Booking
                  </Dropdown.Item>
                  <Dropdown.Item href="/manage/notifyuser">
                    Manage Notification
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
            {token && role === "dentist" && (
              <Dropdown
                onMouseEnter={() => setShowDentistSetting(true)}
                onMouseLeave={() => setShowDentistSetting(false)}
              >
                <Dropdown.Toggle
                  as={Nav.Link}
                  id="mynav-link"
                  active={url.startsWith("/manage") ? true : false}
                >
                  Dentist <MdSettings />
                </Dropdown.Toggle>
                <Dropdown.Menu id="dropdown-menu" show={showDentistSetting}>
                  <Dropdown.Item href="/manage/booking">
                    Manage Booking
                  </Dropdown.Item>
                  <Dropdown.Item href="/manage/schedule">
                    Create Schedule
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </Nav>

          {token ? (
            <Nav>
              {(role === "dentist" || role === "admin" || role === "staff") && (
                <Nav.Link
                  id="mynav-link"
                  href="/staff/profile"
                  active={url.startsWith("/staff") ? true : false}
                >
                  <BsPersonFillGear className="text-danger" />
                  {fname[0] + "." + lname[0] + "."}Profile
                </Nav.Link>
              )}
              {role === "user" && (
                <Nav.Link
                  id="mynav-link"
                  href="/user/dashboard"
                  active={url.startsWith("/user") ? true : false}
                >
                  <BsFillPersonCheckFill className="text-warning" />
                  {fname[0] + "." + lname[0] + "."}Dashboard
                </Nav.Link>
              )}
              <Nav.Item as={Nav.Link} onClick={signOut}>
                <FaSignOutAlt /> Logout
              </Nav.Item>
            </Nav>
          ) : (
            <Nav>
              <Nav.Link id="mynav-link">
                <span className="d-flex">
                  <UserSignInModal />
                  /SignUp
                </span>
              </Nav.Link>
            </Nav>
          )}
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}
