import React, { useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";

import AuthContext from "../../context/AuthContext";
import ListContext from "../../context/ListContext";
import { BiTime } from "react-icons/bi";
import { FaClinicMedical, FaNotesMedical, FaUserNurse } from "react-icons/fa";
import UserBookingsCencalModal from "./UserBookingsCancelModal";

/**
 * Show user bookings: future and past
 * @param {current} true: future appts; false: past appts
 * @param {user_id} used by staff to query user's appts (optional)
 * @returns
 */
export default function UserBookings({ current, user_id }) {
  const { token, role } = useContext(AuthContext);
  const { reasonList, dentistList } = useContext(ListContext);
  const [loadedAppts, setLoadedApps] = useState([]);

  const fetchFutureAppts = useCallback(async () => {
    if (!token) return;
    const authStr = "Bearer " + token;
    let resDoc;
    try {
      if (role === "user") {
        resDoc = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/appt/user/${
            current ? "future" : "past"
          }`,
          {
            headers: { Authorization: authStr },
          }
        );
      } else if (role === "admin") {
        resDoc = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/appt/staff/user/${
            current ? "future" : "past"
          }/${user_id}`,
          {
            headers: { Authorization: authStr },
          }
        );
      }
      if (resDoc.status === 200) {
        setLoadedApps(resDoc.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [token, role, user_id, current]);
  useEffect(() => {
    fetchFutureAppts();
  }, [fetchFutureAppts]);

  const cancelAppt = async (appt_id) => {
    const authStr = "Bearer " + token;
    try {
      const resDoc = await axios.put(
        process.env.REACT_APP_BACKEND_URL + `/appt/user/cancel/${appt_id}`,
        {},
        {
          headers: { Authorization: authStr },
        }
      );
      if (resDoc.status === 201) {
        // confirming appointment has been booked
        // reload appointments to force a re-render of the page.
        fetchFutureAppts();
        // console.log(resDoc.status);
      } else if (resDoc.status === 208) {
        // appointment not vacant, please reselect
        // console.log(resDoc.status);
      }
    } catch (error) {
      console.error(error);
    }
    // window.alert(event.appt_id);
  };

  const dentist_idToName = (dentist_id) => {
    const dentist = dentistList.find((dentist) => dentist._id === dentist_id);
    return dentist.fname + " " + dentist.lname;
  };

  return (
    <div className="container">
      <h3>My {current ? "Current" : "Past"} Bookings</h3>

      {loadedAppts.length > 0 ? (
        <div className="row">
          {loadedAppts.map((appt) => (
            <div className="col-lg-6" key={appt._id}>
              <div className="card shadow mb-3">
                <div
                  className={`card-header text-bg-${
                    current ? "success" : "secondary"
                  }`}
                >
                  <h5>
                    Appointment: {new Date(appt.start).toLocaleDateString()}
                  </h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-1 mb-1">
                      <BiTime />
                    </div>
                    <div className="col-11">
                      {new Date(appt.start)
                        .toLocaleTimeString("en-AU", {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        })
                        .replace(/ /, "")}
                      -
                      {new Date(appt.end)
                        .toLocaleTimeString("en-AU", {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        })
                        .replace(/ /, "")}
                    </div>
                    <div className="col-1 mb-1">
                      <FaUserNurse />
                    </div>
                    <div className="col-11">
                      Dr {dentist_idToName(appt.dentist_id)}
                    </div>
                    <div className="col-1 mb-1">
                      <FaClinicMedical />
                    </div>
                    <div className="col-11">
                      {process.env.REACT_APP_COMPANY}
                    </div>
                    <div className="col-1 mb-1">
                      <FaNotesMedical />
                    </div>
                    <div className="col-11">
                      {reasonList.find(
                        (reason) => reason._id === appt.reason_id
                      ) &&
                        reasonList.find(
                          (reason) => reason._id === appt.reason_id
                        ).name}
                    </div>

                    <div className="col-4 fw-bold">Patient:</div>
                    <div className="col-8"></div>
                    <div className="col-3 text-end">Name:</div>
                    <div className="col-9">
                      {appt.patient.fname} {appt.patient.lname}
                    </div>
                    <div className="col-3 text-end">DOB:</div>
                    <div className="col-9">
                      {new Date(appt.patient.DOB).toLocaleDateString()}
                    </div>
                    <div className="col-3 text-end">Note:</div>
                    <div className="col-9">{appt.patient.note}</div>
                  </div>
                </div>
                {current && (
                  <div className="card-footer text-end">
                    <UserBookingsCencalModal
                      cancelAppt={cancelAppt}
                      appt_id={appt._id}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <>
          <h3>No appointment to show</h3>
        </>
      )}
    </div>
  );
}
