import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { Calendar, dateFnsLocalizer, Views } from "react-big-calendar";
import { format, parse, startOfWeek, getDay } from "date-fns";
import "react-big-calendar/lib/css/react-big-calendar.css";

import "./BookManage.css";
import AuthContext from "../../context/AuthContext";
import BookManageApptModal from "./BookManageApptModal";
import BookManageVacantModal from "./BookManageVacantModal";
import BookManageSlotModal from "./BookManageSlotModal";

const locales = {
  "en-AU": require("date-fns/locale/en-AU"),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
  getDay,
  locales,
});

export default function BookManage() {
  const { token, role, fname, lname } = useContext(AuthContext);
  const [apptEventList, setApptEventList] = useState([]);
  const [selEvent, setSelEvent] = useState();
  const [selSlot, setSelSlot] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showSlotModal, setShowSlotModal] = useState(false);
  const [showVacantEventModal, setShowVacantEventModal] = useState(false);
  const [rangeStart, setRangeStart] = useState(getMonday0Hr(new Date()));
  const [rangeEnd, setRangeEnd] = useState(
    getMonday0Hr(newAddDays(new Date(), 7))
  );

  // load Appointments between startDate and endDate
  // convert appointments into events to be displayed in calendar
  const getApptRange = useCallback(async () => {
    if (!token) return;
    const authStr = "Bearer " + token;
    try {
      // load all appointments within 30 days, and map them into calendar events
      const resDoc = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/appt/rangeByStaff",
        {
          params: { start: rangeStart, end: rangeEnd },
          headers: { Authorization: authStr },
        }
      );
      if (resDoc.status === 200 && resDoc.data.length > 0) {
        // map appointments into calendar events
        const _apptArr = resDoc.data;

        let _apptEvents = [];
        for (let i = 0; i < _apptArr.length; i++) {
          _apptEvents.push({
            status: _apptArr[i].status,
            start: new Date(_apptArr[i].start),
            end: new Date(_apptArr[i].end),
            appt_id: _apptArr[i]._id,
            dentistInit:
              _apptArr[i].dentist_id?.fname.substring(0, 1) +
              _apptArr[i].dentist_id?.lname.substring(0, 1),
            userName: _apptArr[i].user_id
              ? _apptArr[i].user_id.fname.substring(0, 1) +
                "." +
                _apptArr[i].user_id.lname
              : null,
            userPhone: _apptArr[i].user_id ? _apptArr[i].user_id.phone : null,
          });
        }
        setApptEventList([..._apptEvents]);
      } else {
        // cannot load appointment
      }
    } catch (error) {
      console.error(error);
    }
  }, [token, rangeStart, rangeEnd]);

  // initially, the calendar should be in Week view starting on Monday of current week
  // loading Appointments from Monday (0hr) to the following Monday (0hr)
  useEffect(() => {
    getApptRange();
  }, [getApptRange]);

  // Add "days" to "startDate"
  function newAddDays(startDate, days) {
    let result = new Date(startDate);
    result.setDate(result.getDate() + days);
    return result;
  }

  // get Monday of the week, and set to start of the day: 0hr 0min 0sec 0mil
  function getMonday0Hr(d) {
    // d = new Date(d);
    let day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    let retDate = new Date(d.setDate(diff));
    retDate.setHours(0, 0, 0, 0);
    return retDate;
  }

  // click on an appointment: book this appointment if it is vacant
  // if appointment is already booked, send alert
  const handleSelectEvent = useCallback(async (event) => {
    if (!event) return;
    if (event.status === "vacant") {
      // clicking on a vacant slot: book this slot for a patient
      setSelEvent(event);
      setShowVacantEventModal(true);
    } else if (event.status === "booked") {
      // cliking on a booked slot - view and manage this booking
      setSelEvent(event);
      setShowModal(true);
    }
  }, []);

  // click on an empty slot: creat an appointment with start/end of that slot
  const handleSelectSlot = useCallback(async ({ start, end }) => {
    setSelSlot({ start: start, end: end });
    setShowSlotModal(true);
  }, []);

  /**
   * when calendar navigates, e.g., 'Back', 'Next', 'Week', 'Day'
   * load Appointments within the new range to update
   */
  const onRangeChange = useCallback(
    (range) => {
      const _rangeStart = new Date(range[0]).setHours(0, 0, 0, 0);
      setRangeStart(_rangeStart);
      const _rangeEnd = new Date(range.slice(-1)).setHours(23, 59, 59, 999);
      setRangeEnd(_rangeEnd);
      getApptRange(_rangeStart, _rangeEnd);
    },
    [getApptRange]
  );

  // define event styles based on status
  // styles defined in .css file
  const eventStyleGetter = useCallback((event, start, end, isSelected) => {
    if (event.status === "vacant") {
      return { className: "vacant-event" };
      // return { style: { backgroundColor: "skyblue", border: "0px" } };
    } else if (event.status === "booked") {
      if (!/^04/.test(event.userPhone)) {
        return { className: "landline-user" };
      }
      return { className: "booked-event" };
    }
  }, []);

  // constrol texts displayed on the event
  // timeslot label was hidden by .css style
  const components = useMemo(
    () => ({
      event: (props) => {
        return (
          <div>
            {props.event.dentistInit}:
            {props.event.status === "vacant" ? "vacant" : props.event.userName}
          </div>
        );
      },
    }),
    []
  );

  return (
    <div className="calendar-container">
      <h1>Booking Calendar</h1>
      <h5>
        for {fname} {lname} ({role})
      </h5>
      <Calendar
        defaultDate={new Date()}
        localizer={localizer}
        events={apptEventList}
        // backgroundEvents={bgEvents}
        min={new Date(2020, 1, 0, 8)}
        max={new Date(2020, 1, 0, 22)}
        startAccessor="start"
        endAccessor="end"
        defaultView={Views.WEEK}
        views={[Views.WEEK, Views.DAY]}
        step={30} // 15 min/slot
        timeslots={2} // 4 slots/section
        selectable
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        eventPropGetter={eventStyleGetter}
        onRangeChange={onRangeChange}
        components={components}
        style={{
          height: 1000,
        }}
      />
      {showVacantEventModal && (
        <BookManageVacantModal
          showVacantEventModal={showVacantEventModal}
          setShowVacantEventModal={setShowVacantEventModal}
          appt_id={selEvent && selEvent.appt_id}
          getApptRange={getApptRange}
        />
      )}
      {showModal && (
        <BookManageApptModal
          showModal={showModal}
          setShowModal={setShowModal}
          appt_id={selEvent && selEvent.appt_id}
          getApptRange={getApptRange}
        />
      )}
      {showSlotModal && (
        <BookManageSlotModal
          showSlotModal={showSlotModal}
          setShowSlotModal={setShowSlotModal}
          selSlot={selSlot}
          getApptRange={getApptRange}
        />
      )}
    </div>
  );
}
