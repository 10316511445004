import { createContext } from "react";

const AuthContext = createContext({
  token: null,
  user_id: "",
  fname: "",
  lname: "",
  role: "",
  login: () => {},
  logout: () => {},
});

export default AuthContext;
