import React, { useState, useContext } from "react";
import axios from "axios";
import { FaSave } from "react-icons/fa";

import AuthContext from "../../context/AuthContext";

export default function StaffManageEdit({ index, staff }) {
  const { token } = useContext(AuthContext);
  const [editStaff, setEditStaff] = useState({
    ...staff,
  });

  const handleChange = (event) => {
    setEditStaff((prevEx) => {
      return {
        ...prevEx,
        [event.target.name]: event.target.value,
      };
    });
  };

  async function saveUpdate() {
    const authStr = "Bearer " + token;
    try {
      const resDoc = await axios.put(
        process.env.REACT_APP_BACKEND_URL + "/staff/update/" + staff._id,
        editStaff,
        {
          headers: { Authorization: authStr },
        }
      );
      if (resDoc.status === 201) {
        // updated - refresh page
        window.location.reload(false);
      } else {
        console.error(resDoc.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className={index % 2 ? "mt-1" : "mt-1 bg-white"}>
      <div className="row">
        <div className="col-1">{editStaff.title}</div>
        <div className="col-1">{editStaff.fname}</div>
        <div className="col-1">{editStaff.lname}</div>
        <div className="col-2">{editStaff.position}</div>
        <div className="col-2">{editStaff.email}</div>
        <div className="col-1">{editStaff.phone}</div>
        <div className="col-1">
          <input
            className="form-control"
            type="number"
            name="seq"
            defaultValue={editStaff.seq}
            onChange={handleChange}
          />
        </div>
        <div className="col-1">
          <select
            className="form-select"
            defaultValue={editStaff.status}
            name="status"
            onChange={handleChange}
          >
            <option disabled>status</option>
            <option value="active">active</option>
            <option value="inactive">inactive</option>
            <option value="disabled">disabled</option>
          </select>
        </div>
        <div className="col-1">
          <select
            className="form-select"
            defaultValue={editStaff.role}
            name="role"
            onChange={handleChange}
          >
            <option disabled value="">
              role
            </option>
            <option value="staff">staff</option>
            <option value="dentist">dentist</option>
            <option value="admin">admin</option>
          </select>
        </div>

        <div className="col-1">
          <button className="btn btn-link" onClick={saveUpdate}>
            <FaSave />
          </button>
        </div>
      </div>
    </div>
  );
}
