import { useContext } from "react";

import "./Service.css";
import ListContext from "../../context/ListContext";

export default function ServiceBody() {
  const { serviceList } = useContext(ListContext);

  return (
    <>
      <div className="row g-3">
        {serviceList.length > 0 &&
          serviceList.map((service) => {
            return (
              <div className="col-6 col-sm-4" key={service._id}>
                <div className="card" id="service-card">
                  <a
                    className="text-decoration-nono"
                    href={`/service/${service.name.replace(/\s/g, "")}`}
                  >
                    <img
                      className="trans-img"
                      src={
                        process.env.REACT_APP_ASSET_URL + "/" + service.iconPath
                      }
                      alt="service icon"
                    />
                  </a>
                  <div className="card-body">
                    <h5 className="card-title">{service.name}</h5>
                    <p className="card-text d-none d-sm-block">
                      {service.shortNote}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        <small className="text-end">
          Dental Icons by{" "}
          <a
            className="text-white"
            target="_blank"
            href="https://icons8.com"
            rel="noreferrer"
          >
            Icons8
          </a>
        </small>
      </div>
    </>
  );
}
