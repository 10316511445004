import React, { useState, useContext } from "react";
import axios from "axios";
import { FaSave } from "react-icons/fa";

import AuthContext from "../../context/AuthContext";

export default function ManageServiceAdd() {
  const { token } = useContext(AuthContext);
  const [newService, setNewService] = useState();

  const [iconImg, setIconImg] = useState(null);
  const handleSelectedIcon = (e) => {
    setIconImg(e.target.files[0]);
  };

  const [svcImg, setSvcImg] = useState(null);
  const handleSelectedImage = (e) => {
    setSvcImg(e.target.files[0]);
  };

  const handleChange = (event) => {
    setNewService((prevEx) => {
      return {
        ...prevEx,
        [event.target.name]: event.target.value,
      };
    });
  };

  async function addService() {
    const authStr = "Bearer " + token;

    const uploadForm = new FormData();
    uploadForm.append("iconImg", iconImg);
    uploadForm.append("svcImg", svcImg);
    // field name 'svcImg' must match backend post: upload.single('svcImg')
    for (const key in newService) {
      uploadForm.append(key, newService[key]);
    }

    try {
      const resDoc = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/service/create",
        uploadForm,
        {
          headers: { Authorization: authStr },
        }
      );
      if (resDoc.status === 201) {
        // service added - refresh page
        window.location.reload();
      } else {
        console.error(resDoc.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="row mt-2 bg-white">
      <div className="col-1">
        <input
          className="form-control"
          required
          type="text"
          name="name"
          placeholder="name"
          onChange={handleChange}
        />
      </div>
      <div className="col-1">
        <input
          className="form-control"
          required
          type="number"
          name="seq"
          placeholder="seq"
          onChange={handleChange}
        />
      </div>
      <div className="col-1">
        <input
          type="file"
          className="form-control-file"
          onChange={handleSelectedIcon}
        />
      </div>
      <div className="col-2 ">
        <input
          type="file"
          className="form-control-file"
          onChange={handleSelectedImage}
        />
      </div>
      <div className="col-2">
        <textarea
          className="form-control"
          name="shortNote"
          rows="3"
          maxLength="200"
          placeholder="short description (about 150 characters)"
          onChange={handleChange}
        />
      </div>
      <div className="col-3">
        <textarea
          className="form-control"
          name="note"
          rows="3"
          placeholder="full description (accept html format, e.g., <p>...</p>)"
          onChange={handleChange}
        />
      </div>
      <div className="col-1">active</div>
      <div className="col-1 text-center">
        <button className="btn btn-link" onClick={addService}>
          <FaSave />
        </button>
      </div>
      <hr />
    </div>
  );
}
