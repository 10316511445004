import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import AuthContext from "../../context/AuthContext";
import "./Staff.css";
import { BsPersonSquare } from "react-icons/bs";
import ListContext from "../../context/ListContext";

export default function StaffProfile() {
  const navigate = useNavigate();
  const { token, role } = useContext(AuthContext);
  const { reasonList } = useContext(ListContext);
  const [updateStaff, setUpdateStaff] = useState();
  const [staffImage, setStaffImage] = useState();
  const [mobNrError, setMobNrError] = useState(false);
  const [updateEnabled, setUpdateEnabled] = useState(false);

  useEffect(() => {
    const fetchStaff = async () => {
      if (!token) return;
      const authStr = "Bearer " + token;
      try {
        const resDoc = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/staff/getSelf",
          {
            headers: { Authorization: authStr },
          }
        );
        setUpdateStaff(resDoc.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchStaff();
  }, [token]);

  useEffect(() => {
    setUpdateEnabled(
      updateStaff &&
        updateStaff.fname &&
        updateStaff.lname &&
        isMobileNr(updateStaff.phone)
    );
  }, [updateStaff]);

  const handleChange = (event) => {
    setUpdateStaff((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  function isMobileNr(mobileNr) {
    return /^04(\s?[0-9]{2}\s?)([0-9]{3}\s?[0-9]{3}|[0-9]{2}\s?[0-9]{2}\s?[0-9]{2})$/.test(
      mobileNr
    );
  }
  const handleMobileChange = (event) => {
    if (isMobileNr(event.target.value)) {
      setMobNrError(false);
    } else {
      setMobNrError(true);
    }
    handleChange(event);
  };

  const handleCheck = (checkedId) => {
    let checkedList = [...updateStaff.reason_list];
    const index = checkedList.indexOf(checkedId);
    if (index > -1) {
      // if checkedId is in the checkedList, remove it
      checkedList.splice(index, 1);
    } else {
      // if checkedId is not in the checkedList, add it
      checkedList.push(checkedId);
    }
    setUpdateStaff((prevState) => {
      return {
        ...prevState,
        reason_list: [...checkedList],
      };
    });
  };

  const handleStreetInput = (event) => {
    setUpdateStaff((prevState) => {
      return {
        ...prevState,
        address: {
          ...prevState.address,
          [event.target.name]: event.target.value,
        },
      };
    });
  };

  async function saveUpdate() {
    const authStr = "Bearer " + token;

    const uploadForm = new FormData();
    uploadForm.append("staffPhoto", staffImage); // field name 'dentistPhoto' must match backend post: upload.single('dentistPhoto')
    for (const key in updateStaff) {
      if (Array.isArray(updateStaff[key])) {
        // for array property, need to append each item of the array
        updateStaff[key].forEach((item) => uploadForm.append(`${key}[]`, item));
      } else if (typeof updateStaff[key] === "object") {
        // for object property, e.g., Address object, need to drill down further
        for (const subK in updateStaff[key]) {
          uploadForm.append(`${key}[${subK}]`, updateStaff[key][subK]);
        }
      } else {
        // a plain property
        uploadForm.append(key, updateStaff[key]);
      }
    }

    try {
      await axios.put(
        process.env.REACT_APP_BACKEND_URL + "/staff/updateSelf",
        uploadForm,
        {
          headers: { Authorization: authStr },
        }
      );
      // window.location.reload(false);
      navigate("/");
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="container">
      <h1>Staff Profile</h1>

      {updateStaff && (
        <div className="row">
          <div className="col-12 col-lg-6 mb-3">
            <div className="card">
              <div className="card-header">
                <div className="row g-3">
                  <div className="col-12 col-lg-6">
                    <label className="form-label" htmlFor="fname">
                      First Name
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="fname"
                      id="fname"
                      defaultValue={updateStaff.fname}
                      onChange={handleChange}
                      placeholder="first name"
                    />
                  </div>
                  <div className="col-8 col-lg-4">
                    <label className="form-label" htmlFor="lname">
                      Last Name
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="lname"
                      id="fname"
                      defaultValue={updateStaff.lname}
                      onChange={handleChange}
                      placeholder="last name"
                    />
                  </div>
                  <div className="col-4 col-lg-2">
                    <label className="form-label" htmlFor="title">
                      Title
                    </label>
                    <select
                      className="form-select"
                      defaultValue={updateStaff.title}
                      name="title"
                      id="title"
                      onChange={handleChange}
                    >
                      <option value="title" disabled>
                        title
                      </option>
                      <option value="Mr">Mr</option>
                      <option value="Dr">Dr</option>
                      <option value="Ms">Ms</option>
                      <option value="Miss">Miss</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="card-body">
                <div className="row g-3">
                  <div className="col-12 col-lg-6">
                    <label className="form-label" htmlFor="email">
                      Email:
                      <input
                        type="text"
                        className="form-control-plaintext"
                        id="email"
                        defaultValue={updateStaff.email}
                      />
                    </label>
                  </div>
                  <div className="col-12 col-lg-6">
                    <label className="form-label" htmlFor="phone">
                      Phone:
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="phone"
                      name="phone"
                      defaultValue={updateStaff.phone}
                      onChange={handleMobileChange}
                      placeholder="☎ Mobile Number"
                    />
                    {mobNrError && (
                      <small style={{ color: "red" }}>
                        enter valid mobie number:
                        <br />
                        e.g., 0450123456
                      </small>
                    )}
                  </div>

                  <div className="col-12 col-lg-6">
                    <label className="form-label" htmlFor="photo">
                      Staff Photo:
                    </label>
                    <input
                      className="form-control-file"
                      type="file"
                      name="staffPhoto"
                      id="photo"
                      onChange={(e) => setStaffImage(e.target.files[0])}
                    />
                  </div>
                  <div className="col-12 col-lg-6">
                    {updateStaff.photoPath ? (
                      <img
                        src={
                          process.env.REACT_APP_ASSET_URL +
                          "/" +
                          updateStaff.photoPath
                        }
                        id="staff-profile-img"
                        alt="img"
                      />
                    ) : (
                      <>
                        <h1>
                          <BsPersonSquare />
                        </h1>
                        <p>Staff photo</p>
                      </>
                    )}
                  </div>

                  {updateStaff.address && (
                    <>
                      <div className="col-12">
                        <label htmlFor="line1" className="form-label">
                          Address:
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="line1"
                          defaultValue={updateStaff.address.line1}
                          onChange={handleStreetInput}
                        />
                      </div>
                      <div className="col-6">
                        <label>Suburb: </label>
                        <input
                          className="form-control"
                          type="text"
                          name="suburb"
                          defaultValue={updateStaff.address.suburb}
                          onChange={handleStreetInput}
                        />
                      </div>
                      <div className="col-3">
                        <label>State: </label>
                        <input
                          className="form-control"
                          type="text"
                          name="state"
                          defaultValue={updateStaff.address.state}
                          onChange={handleStreetInput}
                        />
                      </div>
                      <div className="col-3">
                        <label>Postcode: </label>
                        <input
                          className="form-control"
                          type="text"
                          name="postcode"
                          defaultValue={updateStaff.address.postcode}
                          onChange={handleStreetInput}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="card-footer">
                <div className="row justify-content-end">
                  <div className="col-auto">
                    <a className="btn btn-outline-primary" href="/">
                      Back to Home
                    </a>
                  </div>
                  <div className="col-auto">
                    <button
                      className="btn btn-primary"
                      disabled={!updateEnabled}
                      onClick={saveUpdate}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6">
            {(role === "dentist" || role === "admin" || role === "staff") && (
              <div className="card">
                <div className="card-header">
                  <h5>Staff Specific Info</h5>
                </div>
                <div className="card-body">
                  <div className="row mb-3">
                    <label
                      className="col-12 col-lg-3 col-form-label"
                      htmlFor="position"
                    >
                      Position
                    </label>
                    <div className="col-12 col-lg-9">
                      <input
                        className="form-control"
                        type="text"
                        name="position"
                        id="position"
                        defaultValue={updateStaff.position}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      className="col-12 col-lg-3 col-form-lable"
                      htmlFor="bio"
                    >
                      Bio
                    </label>
                    <div className="col-12 col-lg-9">
                      <textarea
                        className="form-control"
                        rows="5"
                        name="bio"
                        id="bio"
                        defaultValue={updateStaff.bio}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  {role === "dentist" && (
                    <div className="row mb-3">
                      <label className="col-12 col-lg-3 col-form-label">
                        Reasons to look after:
                      </label>
                      <div className="col-12 col-lg-9">
                        {reasonList.map((reason) => (
                          <div className="form-check" key={reason._id}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="reason_id"
                              checked={updateStaff.reason_list.includes(
                                reason._id
                              )}
                              id={reason._id}
                              onChange={() => handleCheck(reason._id)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={reason._id}
                            >
                              {reason.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className="card-footer">
                  <div className="row justify-content-end">
                    <div className="col-auto">
                      <a className="btn btn-outline-primary" href="/">
                        Back to Home
                      </a>
                    </div>
                    <div className="col-auto">
                      <button
                        className="btn btn-primary"
                        disabled={!updateEnabled}
                        onClick={saveUpdate}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
