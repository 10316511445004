import React, { useState } from "react";
import axios from "axios";

// Source: https://itnext.io/password-reset-emails-in-your-react-app-made-easy-with-nodemailer-bb27968310d7

export default function StaffForgotPassword({ emailInput }) {
  const [emailSent, setEmailSent] = useState(false);
  const [errorMsg, setErrorMsg] = useState();

  const sendEmail = async () => {
    try {
      const responseData = await axios.patch(
        process.env.REACT_APP_BACKEND_URL + "/staff/forgotPassword",
        {
          email: emailInput,
          resetPasswordLink:
            process.env.REACT_APP_FRONTEND_URL + "/staff/resetPassword/",
        }
      );
      if (responseData.status === 200) {
        setEmailSent(true);
      } else {
        setErrorMsg("cannot complete");
      }
    } catch (error) {
      console.error(error.response);
      if (error.response.status === 404) {
        setErrorMsg(error.response.data + " Please create an account.");
      } else {
        setErrorMsg(error.response.data);
      }
    }
  };

  return (
    <div>
      {emailSent ? (
        <div className="alert alert-info text-end">
          <p className="text-start">
            Password Recovery email sent. Please check your email. Note: the
            Password Reset Email may be in your{" "}
            <span style={{ backgroundColor: "yellow" }}>Spam Email</span> or{" "}
            <span style={{ backgroundColor: "yellow" }}>Junk Email</span>{" "}
            folder.
          </p>
          <a href="/" className="btn btn-success ml-auto">
            Return
          </a>
        </div>
      ) : errorMsg ? (
        <div className="alert alert-danger">Error: {errorMsg}</div>
      ) : (
        <div className="alert alert-warning text-end">
          <p className="text-start">
            Request a password reset by entering your email above and click on
            the <span className="bg-warning">Reset&nbsp;Password</span> button
            below. You will then receive a password reset email.
          </p>
          <button className="btn btn-warning" onClick={sendEmail}>
            Reset Password
          </button>
        </div>
      )}
    </div>
  );
}
