import React from "react";

import "./Home.css";

export default function NoGapButton() {
  return (
    <div id="no-gap">
      NO GAP
      <br />
      Check-up &<br /> Clean Offer
      <br />
      <a style={{ color: "inherit", fontWeight: 900 }} href="/book-now">
        <u>Book Now</u>
      </a>
      <div id="no-gap-tcs">T&CS Apply</div>
    </div>
  );
}
