import React, { useState, useContext } from "react";
import axios from "axios";

import AuthContext from "../../context/AuthContext";
import { FaSave } from "react-icons/fa";

export default function ManageReasonEdit({ index, reason }) {
  const { token } = useContext(AuthContext);
  const [editReason, setEditReason] = useState(reason);

  const handleChange = (event) => {
    setEditReason((prevEx) => {
      return {
        ...prevEx,
        [event.target.name]: event.target.value,
      };
    });
  };

  async function saveUpdate() {
    const authStr = "Bearer " + token;
    try {
      const resDoc = await axios.put(
        process.env.REACT_APP_BACKEND_URL + "/reason/update/" + editReason._id,
        editReason,
        {
          headers: { Authorization: authStr },
        }
      );
      if (resDoc.status === 201) {
        // updated - refresh page
        window.location.reload(false);
      } else {
        console.error(resDoc.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className={index % 2 ? "form row mt-2 bg-white" : "form row mt-2"}>
      <div className="col-2">
        <input
          required
          className="form-control"
          type="text"
          name="name"
          defaultValue={editReason.name}
          onChange={handleChange}
          placeholder="name"
        />
      </div>
      <div className="col-1">
        <input
          required
          className="form-control"
          type="number"
          name="seq"
          defaultValue={editReason.seq}
          onChange={handleChange}
          placeholder="seq"
        />
      </div>
      <div className="col-4">
        <input
          className="form-control"
          type="text"
          name="note"
          defaultValue={editReason.note}
          onChange={handleChange}
          placeholder="note"
        />
      </div>
      <div className="col-1">
        <select
          className="form-select"
          defaultValue={editReason.status}
          name="status"
          onChange={handleChange}
        >
          <option value="active">active</option>
          <option value="inactive">inactive</option>
        </select>
      </div>
      <div className="col-1 text-center">
        <button className="btn btn-link" onClick={saveUpdate}>
          <FaSave />
        </button>
      </div>
    </div>
  );
}
