import { Navigate, Outlet } from "react-router-dom";

export default function StaffRoute() {
  const ls_user = JSON.parse(
    localStorage.getItem(process.env.REACT_APP_LS_USER)
  );

  return ls_user &&
    (ls_user.role === "admin" ||
      ls_user.role === "dentist" ||
      ls_user.role === "staff") ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
}
