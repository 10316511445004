import React, { useState, useEffect, useContext } from "react";
import { BsPersonSquare } from "react-icons/bs";

import "./AboutUs.css";
import { useParams } from "react-router-dom";
import Title from "../MyNavbar/Title";
import AboutUsTitleImg from "./edc-aboutus-title.jpg";
import ListContext from "../../context/ListContext";

export default function AboutUsBodyStaff() {
  const { staffName } = useParams();
  const { staffList } = useContext(ListContext);

  const [selStaff, setSelStaff] = useState();

  useEffect(() => {
    const _staff = staffList.find(
      (staff) => staff.fname + staff.lname === staffName
    );
    setSelStaff({ ..._staff });
  }, [setSelStaff, staffList, staffName]);

  return (
    <>
      {selStaff && (
        <>
          <Title
            bg_img={AboutUsTitleImg}
            title={`About Us - ${selStaff.title} ${selStaff.fname} ${selStaff.lname}`}
          />

          <div className="container py-5">
            <div className="row g-2">
              <div className="col-lg-4 text-center">
                {selStaff.photoPath ? (
                  <img
                    src={
                      process.env.REACT_APP_ASSET_URL + "/" + selStaff.photoPath
                    }
                    id="aboutus-staff-img"
                    alt="img"
                  />
                ) : (
                  <>
                    <h1>
                      <BsPersonSquare />
                    </h1>
                    <p>Staff photo</p>
                  </>
                )}
              </div>
              <div className="col-lg-8">
                <h3>
                  {selStaff.title} {selStaff.fname} {selStaff.lname}
                </h3>
                <h5>{selStaff.position}</h5>
                <div dangerouslySetInnerHTML={{ __html: selStaff.bio }} />
                {/* <p>{selStaff.bio}</p> */}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
