import React from "react";

export default function UserManageColumn() {
  return (
    <div className="row bg-primary text-white font-weight-bold">
      <div className="col-2">FirstName*</div>
      <div className="col-1">LastName*</div>
      <div className="col-2">phone*</div>
      <div className="col-2">email</div>
      <div className="col-1">DOB*</div>
      <div className="col-1">status</div>
      {/* <div className="col-1">role</div> */}
      <div className="col-2">insurance</div>
      <div className="col-1">action</div>
    </div>
  );
}
