import React from "react";

import Title from "../MyNavbar/Title";
import ContactImg from "./edc-title-contactus.jpg";
import ContactUsBody from "./ContactUsBody";
import NoGapButton from "../Home/NoGapButton";

export default function ContactUs() {
  return (
    <>
      <Title
        bg_img={ContactImg}
        title={<span>Contact {process.env.REACT_APP_COMPANY} </span>}
      />
      <ContactUsBody />
      <NoGapButton />
    </>
  );
}
