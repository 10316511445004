import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./BookNow.css";
import ListContext from "../../context/ListContext";

export default function BookNow() {
  const { reasonList, dentistList } = useContext(ListContext);
  const navigate = useNavigate();
  // load vacant appointments at page initial load
  const [loadedAppts, setLoadedAppts] = useState([]);

  // thirtyDayAppts[] contains all vacant appointmdents, filtered based on user selections of Reason and Dentist
  // grouped in a two-dimensional array [[day1-appts], [day2-appts], ...],
  // where each thirtyDayAppts[i][*] is an array containing all appointment in the same day
  const [thirtyDayAppts, set30DayAppts] = useState([]);

  // sevenDayAppts[] is a 7-day sub-array (slice) of the thirtyDayAppts, starting from the first available date after startDate
  // sevenDayAppts[] is displayed on screen
  const [startDate, setStartDate] = useState(new Date());
  const [sevenDayAppts, set7DayAppts] = useState([]);

  // availableDates[] contains all available dates - to be included (highlighted) in DatePicker
  const [availableDates, setAvailableDates] = useState([]);

  // dentistList[] for dentist select
  const [dentist_id, setDentist_id] = useState("any");
  const [availDentList, setAvailDentList] = useState([]);

  // reasonList and reason_id for user to select
  const [reason_id, setReason_id] = useState("any");
  const [availReasonList, setAvailReasonList] = useState([]);

  // when initiating, load all vacant appointments within 30 days
  // the loaded appointments were sorted (in Mongoose) by .start date and .createDate
  useEffect(() => {
    const loadAppts = async () => {
      try {
        const resDoc = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/appt/vacant/range",
          { params: { start: new Date(), end: newAddDays(new Date(), 30) } }
        );
        if (resDoc.status === 200) {
          setLoadedAppts([...resDoc.data]);
        } else {
          // cannot load appointment
        }
      } catch (error) {
        console.error(error);
      }
    };
    loadAppts();
  }, []);

  // After user selected dentist - filter reasonList to the reasons which are avaible for the selected reason
  useEffect(() => {
    if (dentist_id === "any") {
      setAvailReasonList(reasonList);
    } else {
      const selDentist = dentistList.find(
        (dentist) => dentist._id === dentist_id
      );
      setAvailReasonList(
        reasonList.filter((reason) =>
          selDentist.reason_list.includes(reason._id)
        )
      );
    }
  }, [dentist_id, dentistList, reasonList]);

  // After user selected reason_id - filter dentistList to the dentists who are avaible for the selected reason
  useEffect(() => {
    if (reason_id === "any") {
      setAvailDentList(dentistList);
    } else {
      setAvailDentList(
        dentistList.filter((dentist) => dentist.reason_list.includes(reason_id))
      );
    }
  }, [reason_id, dentistList]);

  useEffect(() => {
    if (loadedAppts.length > 0) {
      // filter the loadedApps according to user selection of reason_id
      let _apptsByReason = [];
      if (reason_id === "any") {
        // user hasn't select a reason - no filter on reason
        _apptsByReason = loadedAppts;
      } else {
        // user has selected a reason (reason_id) - filter the Appts by selected reason_id
        // for each appointment, retrieve the dentist from appt's dentist_id,
        // and then check if the dentist's reason_list includes the selected reason_id
        _apptsByReason = loadedAppts.filter((appt) =>
          dentistList
            .find((dentist) => dentist._id === appt.dentist_id)
            .reason_list.includes(reason_id)
        );
      }
      if (_apptsByReason.length > 0) {
        // filter the loadedApps according to user selection of dentist_id
        let _apptsByDentist = [];
        if (dentist_id === "any") {
          _apptsByDentist = _apptsByReason;
        } else {
          _apptsByDentist = _apptsByReason.filter(
            (item) => item.dentist_id === dentist_id
          );
        }
        if (_apptsByDentist.length > 0) {
          // format the _apptsByDentist into a two-dimensional array in all days
          // eliminate duplicate Appts: if more than one Appt has the same start, only keep the first one, which has the earliest createDate
          let _allDaysAppts = []; //
          let _availDates = []; // dates that have appts available
          let _thisDayAppts = [_apptsByDentist[0]]; // appts in the current day, initialise to
          for (let i = 1; i < _apptsByDentist.length; i++) {
            if (
              new Date(_apptsByDentist[i].start).toLocaleDateString() ===
              new Date(_apptsByDentist[i - 1].start).toLocaleDateString()
            ) {
              // this i-th appt is in same day as previous (i-1)th appt - add it to this day appts array, but only add unique timeslot
              if (_apptsByDentist[i].start !== _apptsByDentist[i - 1].start) {
                // for multiple appts at the same time, only add the first appt for the time slot, skip other appts at the same time
                _thisDayAppts.push(_apptsByDentist[i]);
              }
            } else {
              // this i-th appt is in a new day, so push this day appts into the main all days array
              _allDaysAppts.push(_thisDayAppts);
              _availDates.push(new Date(_thisDayAppts[0].start));
              // and start a new day with the current appt
              _thisDayAppts = [_apptsByDentist[i]];
            }
          }
          // push the final day appts into the main all days array
          _allDaysAppts.push(_thisDayAppts);
          // push the final day into available dates array
          _availDates.push(new Date(_thisDayAppts[0].start));

          set30DayAppts([..._allDaysAppts]);
          setAvailableDates([..._availDates]);
          return;
        }
      }
    }
    // filtered result is empty
    set30DayAppts([]);
    // set7DayAppts([]);
    setAvailableDates([]);
  }, [loadedAppts, reason_id, dentist_id, dentistList]);

  // render when user select a startDate from Datepicker, update the sevenDayAppts list
  // first, set startDate to 0 hour of the day (to make sure any appointments in the same day is larger than startDate)
  // then, find the index of the first appointment that is larger than startDate
  useEffect(() => {
    startDate.setHours(0, 0, 0, 0);
    const firstD = thirtyDayAppts.findIndex(
      (appt) => new Date(appt[0].start) > startDate
    );

    if (firstD >= 0) {
      let _appt7DArr = thirtyDayAppts.slice(firstD, firstD + 7);
      // console.log(_appt7DArr);
      set7DayAppts(_appt7DArr);
    } else {
      // there is no available appt for the selection
      set7DayAppts([]);
    }
  }, [startDate, thirtyDayAppts]);

  // Add "days" to "startDate"
  function newAddDays(startDate, days) {
    let result = new Date(startDate);
    result.setDate(result.getDate() + days);
    return result;
  }

  function selectAppt(appt_id) {
    const _reason_id =
      reason_id === "any"
        ? reasonList.find((reason) => reason.name === "Unspecified")._id
        : reason_id;
    navigate(`/book-appt/${appt_id}/${_reason_id}`);
  }

  return (
    <div className="container">
      <h3>Book an Appointment</h3>
      <div className="row mb-2">
        {/* <div className="col-md-2">Select a Reason:</div> */}
        <div className="col-lg-6 mb-2">
          <div className="row">
            <label className="col-4 col-form-label" htmlFor="select-reason">
              Select&nbsp;Reason:
            </label>
            <div className="col-8">
              <select
                className="form-select"
                id="select-reason"
                defaultValue="any"
                name="reason_id"
                onChange={(e) => setReason_id(e.target.value)}
              >
                <option value="any">Any Reason</option>
                {availReasonList.length > 0 &&
                  availReasonList.map((reason) => (
                    <option value={reason._id} key={reason._id}>
                      {reason.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>

        {/* <div className="col-md-2">Find a Dentist:</div> */}
        <div className="col-lg-6 mb-2">
          <div className="row">
            <label className="col-4 col-form-label" htmlFor="find-dentist">
              Find&nbsp;a&nbsp;Dentist:
            </label>
            <div className="col-8">
              <select
                className="form-select"
                id="find-dentist"
                defaultValue="any"
                name="dentist_id"
                onChange={(e) => setDentist_id(e.target.value)}
              >
                <option value="any">Any Dentist</option>
                {availDentList.length > 0 &&
                  availDentList.map((dentist) => (
                    <option value={dentist._id} key={dentist._id}>
                      Dr {dentist.fname} {dentist.lname}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-9 mb-2">
          <span className="fw-bold">
            Availability starting:{" "}
            {sevenDayAppts.length > 0 &&
              new Date(sevenDayAppts[0][0].start).toDateString()}
          </span>
          {sevenDayAppts.length > 0 ? (
            sevenDayAppts.map((day) => (
              <div key={day[0].start}>
                <h5 className="text-primary">
                  {new Date(day[0].start).toDateString()}
                </h5>

                {day.map((appt) => (
                  <button
                    key={appt._id}
                    className="btn btn-success btn-timeslot"
                    onClick={() => selectAppt(appt._id)}
                  >
                    {new Date(appt.start).toLocaleTimeString("en-US", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </button>
                ))}
              </div>
            ))
          ) : (
            <h3>No available appointment</h3>
          )}
        </div>

        <div className="col-lg-3 mb-2">
          <span className="fw-bold">Choose available dates:</span>
          <div className="col-auto">
            <div style={{ width: "230px" }}>
              <ReactDatePicker
                dateFormat="dd/MM/yyyy"
                calendarStartDay={1} // week start: Monday
                placeholderText={new Date().toLocaleDateString()}
                selected={startDate}
                onChange={(selDate) => setStartDate(selDate)}
                // only have available days active, others disabled
                includeDates={availableDates}
                // minDate={new Date()}
                // maxDate={newAddDays(new Date(), 30)}
                showDisabledMonthNavigation
                inline
                monthsShown={2}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
