import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function CreateScheduleModal({
  startDate,
  endDate,
  createSched,
}) {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button variant="success" onClick={() => setShow(true)}>
        Create Schedule for the Week
      </Button>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create Schedule for the Week</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you want to create schedule for the week:
          <br />
          <b>
            {startDate.toDateString()} - {endDate.toDateString()}
          </b>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={() => {
              createSched();
              setShow(false);
            }}
          >
            Create Schedule
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
