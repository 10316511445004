//import useState hook to create menu collapse state
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiFillSchedule, AiOutlineSchedule } from "react-icons/ai";
import { BsFillPersonVcardFill } from "react-icons/bs";
import { FaSignOutAlt } from "react-icons/fa";

import AuthContext from "../../context/AuthContext";
import UserBookings from "./UserBookings";
import UserProfile from "./UserProfile";
import Home from "../Home/Home";

export default function UserDashboard() {
  const { token, fname, lname, logout } = useContext(AuthContext);
  const navigates = useNavigate();
  const [itemKey, setItemKey] = useState(0);

  function signOut() {
    logout();
    navigates("/");
  }

  return token ? (
    <div className="row">
      <div className="col-sm-auto sticky-top" style={{ top: "50px" }}>
        <div
          className="d-flex flex-sm-column flex-row flex-nowrap sticky-top align-items-center align-items-sm-start px-2 pt-2 bg-light"
          style={{ top: "50px" }}
        >
          <a
            href="/user/dashboard"
            className="d-block align-items-center pb-3 mb-md-0 me-md-auto text-decoration-none h5"
          >
            <span className="d-none d-sm-inline h3">{fname + " " + lname}</span>
            <span className="d-inline d-sm-none h3">{fname[0] + lname[0]}</span>
          </a>
          <ul
            // className="nav nav-pills flex-sm-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
            className="nav nav-pills nav-flush flex-sm-column flex-row flex-nowrap mb-auto mx-auto  "
            id="menu"
          >
            <li className="nav-item h5" title="current appointments">
              <button className="nav-link" onClick={() => setItemKey(0)}>
                <AiFillSchedule />
                <span className="ms-1 d-none d-sm-inline">Appointment</span>
              </button>
            </li>
            <li className="nav-item h5" title="past appintments">
              <button className="nav-link" onClick={() => setItemKey(1)}>
                <AiOutlineSchedule />
                <span className="ms-1 d-none d-sm-inline">History</span>
              </button>
            </li>
            <li className="nav-item h5">
              <button className="nav-link" onClick={() => setItemKey(2)}>
                <BsFillPersonVcardFill />
                <span className="ms-1 d-none d-sm-inline">My Profile</span>
              </button>
            </li>

            <li className="nav-item h5">
              <button className="nav-link" onClick={signOut}>
                <FaSignOutAlt />
                <span className="ms-1 d-none d-sm-inline">Logout</span>
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div className="col py-3">
        {itemKey === 0 && <UserBookings current={true} />}
        {itemKey === 1 && <UserBookings current={false} />}
        {itemKey === 2 && <UserProfile />}
      </div>
    </div>
  ) : (
    <Home />
  );
}
