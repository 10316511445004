import React, { useState, useContext, useEffect } from "react";
import axios from "axios";

import AuthContext from "../../context/AuthContext";
import ManageServiceColumn from "./ManageServiceColumn";
import ManageServiceItem from "./ManageServiceItem";
import ManageServiceAdd from "./ManageServiceAdd";
import ManageServiceEdit from "./ManageServiceEdit";

export default function ManageService() {
  const { token } = useContext(AuthContext);
  const [allServiceList, setAllServiceList] = useState([]);
  useEffect(() => {
    const fetchService = async () => {
      if (!token) return;
      const authStr = "Bearer " + token;
      try {
        const resDoc = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/service/all",
          {
            headers: { Authorization: authStr },
          }
        );
        if (resDoc.data.length > 0) {
          setAllServiceList([...resDoc.data]);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchService();
  }, [token]);

  const [editService_id, setEditService_id] = useState("");
  const setEdit = (service_id) => {
    setEditService_id(service_id);
  };

  return (
    <div className="container-fluid bg-light text-dark">
      <h1 className="text-center">Manage Services</h1>
      <h5>Add a New Service:</h5>
      <ManageServiceAdd />
      <ManageServiceColumn />
      {allServiceList &&
        allServiceList.map((service, index) => {
          if (service._id === editService_id) {
            return (
              <ManageServiceEdit key={index} index={index} service={service} />
            );
          } else {
            return (
              <ManageServiceItem
                key={index}
                index={index}
                service={service}
                setEdit={setEdit}
              />
            );
          }
        })}
    </div>
  );
}
