import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

// Source: https://itnext.io/password-reset-emails-in-your-react-app-made-easy-with-nodemailer-bb27968310d7

export default function StaffResetPassword() {
  const { emailToken } = useParams();
  const [updateStaff, setUpdateStaff] = useState({});
  const [updated, setUpdated] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  useEffect(() => {
    setSubmitEnabled(
      updateStaff &&
        isValidEmail(updateStaff.email) &&
        isValidPassword(updateStaff.password) &&
        updateStaff.password2
    );
  }, [updateStaff]);

  const handleChange = (event) => {
    setUpdateStaff((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleEmailChange = (event) => {
    if (isValidEmail(event.target.value)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
    handleChange(event);
  };

  function isValidPassword(password) {
    // six or more characters
    return /^.{6,}$/.test(password);
  }
  const handlePasswordChange = (event) => {
    if (isValidPassword(event.target.value)) {
      setPasswordError(false);
    } else {
      setPasswordError(true);
    }
    handleChange(event);
  };

  async function setNewPassword() {
    if (updateStaff.password !== updateStaff.password2) {
      setErrorMsg("Confirm password doesn't match! Try again.");
      return;
    }
    delete updateStaff.password2;
    try {
      const resDoc = await axios.patch(
        process.env.REACT_APP_BACKEND_URL + "/staff/resetpassword",
        { ...updateStaff, emailToken }
      );
      if (resDoc && resDoc.status === 201) {
        setUpdated(true);
      }
    } catch (error) {
      console.error(error.response);
      setErrorMsg(error.response.data);
    }
  }

  return (
    <div className="container mt-3">
      {updated ? (
        <div className="card" style={{ width: "30em" }}>
          <div className="card-header text-bg-success text center">
            <div className="cart-title h5">Password Reset Success</div>
          </div>
          <div className="card-body h5">
            Please log in:{" "}
            <a href="/edc-staff" className="btn btn-warning">
              Log in
            </a>
          </div>
        </div>
      ) : (
        <div className="card" style={{ width: "30em" }}>
          <div className="card-header text-bg-warning text-center">
            <div className="card-title h5">Reset Password</div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-3">Email:</div>
              <div className="col-9 mb-3">
                <input
                  className="form-control"
                  required
                  type="email"
                  name="email"
                  onChange={handleEmailChange}
                  placeholder="📧 email*"
                />
                {emailError && (
                  <small style={{ color: "red" }}>
                    please enter valid email
                  </small>
                )}
              </div>
              {errorMsg && (
                <div className="alert alert-danger">Error: {errorMsg}</div>
              )}

              <div className="col-3">Password:</div>
              <div className="col-9 mb-2">
                <input
                  className="form-control"
                  required
                  type="password"
                  name="password"
                  placeholder="🔒Password (six+ characters)*"
                  onChange={handlePasswordChange}
                />
                {passwordError && (
                  <small style={{ color: "red" }}>
                    enter password with 6 or more characters
                  </small>
                )}
              </div>
              <div className="col-3">Confirm Password:</div>
              <div className="col-9 mb-2">
                <input
                  className="form-control"
                  required
                  type="password"
                  name="password2"
                  onChange={handleChange}
                  placeholder="🔒confirm password*"
                />
              </div>
            </div>
          </div>

          <div className="card-footer text-end">
            <button
              className="btn btn-warning"
              disabled={!submitEnabled}
              onClick={setNewPassword}
            >
              Set New Password
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
