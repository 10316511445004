import axios from "axios";
import { useContext } from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";

import AuthContext from "../../context/AuthContext";
import UserManageItemModal from "./UserManageItemModal";

export default function UserManageItem({ index, user, setEdit }) {
  const { token } = useContext(AuthContext);

  async function deleteUser(user_id) {
    const authStr = "Bearer " + token;
    try {
      const resDoc = await axios.delete(
        process.env.REACT_APP_BACKEND_URL + "/user/delete/" + user_id,
        {
          headers: { Authorization: authStr },
        }
      );
      if (resDoc.status === 201) {
        // deleted - refresh page
        window.location.reload();
      } else {
        console.error(resDoc.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <div className={index % 2 ? "mt-1" : "mt-1 bg-white"}>
        <div className="row">
          <div className="col-2">
            <UserManageItemModal
              user_id={user._id}
              userFName={user.fname}
              userLName={user.lname}
            />
          </div>
          <div className="col-1">{user.lname}</div>
          <div className="col-2">{user.phone}</div>
          <div className="col-2">{user.email}</div>
          <div className="col-1">{new Date(user.DOB).toLocaleDateString()}</div>
          <div className="col-1">{user.status}</div>
          {/* <div className="col-1">{user.role}</div> */}
          <div className="col-2">
            {user.insurance_id && user.insurance_id.name}
          </div>
          <div className="col-1">
            <button className="btn btn-link" onClick={() => setEdit(user._id)}>
              <FaEdit />
            </button>
            <button
              className="btn btn-link"
              disabled={user.status !== "disabled"}
              onClick={() => deleteUser(user._id)}
            >
              <FaTrashAlt />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
