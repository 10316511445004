import React, { useState, useContext } from "react";
import Axios from "axios";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import { BsFillPersonCheckFill } from "react-icons/bs";

import AuthContext from "../../context/AuthContext";
import UserSignUpModal from "./UserSignUpModal";
import UserForgotPassword from "./UserForgotPassword";

export default function UserSignInModal() {
  const { login } = useContext(AuthContext);

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [forgotPassword, setForgotPassword] = useState(false);

  const [loginUser, setLoginUser] = useState({});
  /*
  UserInterface {
  _id?: string;
  fname?: string;
  lname?: string;
  email?: string;
  password?: string;
  password2?: string;
  phone?: string;
  role?: string;
  status?: string;
  address?: AddressInterface;
}*/

  const handleChange = (event) => {
    const { name, value } = event.target;

    setLoginUser((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  async function signIn() {
    try {
      const resDoc = await Axios.post(
        process.env.REACT_APP_BACKEND_URL + "/user/signIn",
        { email: loginUser.email, password: loginUser.password }
      );
      if (resDoc.status === 200) {
        login(
          resDoc.data.token,
          resDoc.data.user_id,
          resDoc.data.fname,
          resDoc.data.lname,
          resDoc.data.role
        );
      }
      setShowLoginModal(false);
    } catch (error) {
      // Axios Handling Errors: https://axios-http.com/docs/handling_errors
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        setErrorMsg(error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log("Error", error.message);
        console.log(error);
      }
      console.log(error.config);
    }
  }

  return (
    <>
      <div onClick={() => setShowLoginModal(true)}>Log In</div>

      <Modal show={showLoginModal} onHide={() => setShowLoginModal(false)}>
        <Modal.Header className="bg-primary text-white" closeButton>
          <Modal.Title>
            <BsFillPersonCheckFill /> User Sign In
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Alert
            show={!!errorMsg}
            variant="danger"
            onClose={() => setErrorMsg("")}
            dismissible
          >
            Error: {errorMsg}.
          </Alert>
          <div className="row mb-1">
            <div className="col-6 mb-1">
              <input
                className="form-control"
                required
                type="email"
                name="email"
                onChange={handleChange}
                placeholder="📧 email"
              />
            </div>
            <div className="col-6 mb-1">
              <input
                className="form-control"
                required
                type="password"
                name="password"
                pattern=".{6,}"
                placeholder="🔒Password (six+ characters)"
                onChange={handleChange}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-primary" type="button" onClick={signIn}>
            <h5>
              <BsFillPersonCheckFill /> Sign In
            </h5>
          </button>
        </Modal.Footer>

        <div className="text-end">
          {forgotPassword ? (
            <UserForgotPassword
              emailInput={loginUser.email}
              setShowLoginModal={setShowLoginModal}
            />
          ) : (
            <div>
              Forgot your password?
              <button
                className="btn btn-link"
                onClick={() => setForgotPassword(true)}
              >
                Reset
              </button>
            </div>
          )}
          <div>
            <span>Don't have an account?</span>
            <button className="btn btn-link">
              <UserSignUpModal />
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
