import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";

import AuthContext from "../../context/AuthContext";
import ListContext from "../../context/ListContext";

export default function UserProfile() {
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const { insuranceList } = useContext(ListContext);
  const [updateUser, setUpdateUser] = useState(null);
  const [errorMsg, setErrorMsg] = useState();
  const [mobNrError, setMobNrError] = useState(false);
  const [updateEnabled, setUpdateEnabled] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      if (!token) return;
      const authStr = "Bearer " + token;
      try {
        const resDoc = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/user/getSelf",
          {
            headers: { Authorization: authStr },
          }
        );
        setUpdateUser(resDoc.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUser();
  }, [token]);

  useEffect(() => {
    setUpdateEnabled(
      updateUser &&
        updateUser.fname &&
        updateUser.lname &&
        isMobileNr(updateUser.phone) &&
        updateUser.DOB
    );
  }, [updateUser]);

  const handleChange = (event) => {
    setUpdateUser((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  function isMobileNr(mobileNr) {
    return /^04(\s?[0-9]{2}\s?)([0-9]{3}\s?[0-9]{3}|[0-9]{2}\s?[0-9]{2}\s?[0-9]{2})$/.test(
      mobileNr
    );
  }
  const handleMobileChange = (event) => {
    if (isMobileNr(event.target.value)) {
      setMobNrError(false);
    } else {
      setMobNrError(true);
    }
    handleChange(event);
  };

  async function saveUpdate() {
    const authStr = "Bearer " + token;
    try {
      const resDoc = await axios.put(
        process.env.REACT_APP_BACKEND_URL + "/user/updateSelf",
        updateUser,
        {
          headers: { Authorization: authStr },
        }
      );
      // window.location.reload(false);
      if (resDoc.status === 201) {
        navigate("/book-now");
      } else {
        setErrorMsg(resDoc.status);
      }
    } catch (error) {
      // Axios Handling Errors: https://axios-http.com/docs/handling_errors
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        setErrorMsg(error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log("Error", error.message);
        console.error(error);
      }
      // console.log(error.config);
    }
  }

  return (
    <div className="container">
      <h3>My Profile</h3>

      {updateUser && (
        <div className="col-lg-6">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <label>First Name</label>
                  <input
                    className="form-control"
                    type="text"
                    name="fname"
                    defaultValue={updateUser.fname}
                    onChange={handleChange}
                    placeholder="first name"
                  />
                </div>
                <div className="col-8 col-lg-4">
                  <label>Last Name</label>
                  <input
                    className="form-control"
                    type="text"
                    name="lname"
                    defaultValue={updateUser.lname}
                    onChange={handleChange}
                    placeholder="last name"
                  />
                </div>
                <div className="col-4 col-lg-2">
                  <label>Title</label>
                  <select
                    className="form-select"
                    defaultValue={updateUser.title}
                    name="title"
                    onChange={handleChange}
                  >
                    <option value="title" disabled>
                      title
                    </option>
                    <option value="Mr">Mr</option>
                    <option value="Dr">Dr</option>
                    <option value="Ms">Ms</option>
                    <option value="Miss">Miss</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="row g-3">
                <div className="col-lg-6">
                  <label className="form-label" htmlFor="email">
                    Email:
                  </label>
                  <input
                    type="text"
                    className="form-control-plaintext"
                    id="email"
                    defaultValue={updateUser.email}
                  />
                </div>
                <div className="col-lg-6">
                  <label className="form-label" htmlFor="phone">
                    Phone:
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="phone"
                    id="phone"
                    // onKeyDown={(event) => event.key.charCodeAt(0) !== 32}
                    defaultValue={updateUser.phone}
                    onChange={handleMobileChange}
                    placeholder="☎ Mobile Number"
                  />
                  {mobNrError && (
                    <small style={{ color: "red" }}>
                      enter valid mobie number:
                      <br />
                      e.g., 0450123456
                    </small>
                  )}
                </div>

                <div className="col-lg-6">
                  <label className="form-label" htmlFor="dob">
                    DOB:
                  </label>
                  <div>
                    <input
                      className="form-control"
                      type="date"
                      name="DOB"
                      id="dob"
                      defaultValue={
                        updateUser.DOB && updateUser.DOB.substring(0, 10)
                      }
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <label className="form-label" htmlFor="insurance">
                    Insurance:
                  </label>
                  <select
                    className="form-select"
                    name="insurance_id"
                    id="insurance"
                    defaultValue={updateUser.insurance_id}
                    onChange={handleChange}
                  >
                    {insuranceList.map((insurance) => (
                      <option
                        key={insurance._id}
                        value={insurance._id}
                        // selected={editUser.insurance_id._id === insurance._id}
                      >
                        {insurance.name}
                      </option>
                    ))}
                  </select>
                </div>
                <Alert
                  show={!!errorMsg}
                  variant="danger"
                  onClose={() => setErrorMsg("")}
                  dismissible
                >
                  Error: {errorMsg}.
                </Alert>
              </div>
            </div>

            <div className="card-footer ">
              <div className="row justify-content-end">
                <div className="col-auto">
                  <a className="btn btn-outline-primary" href="/">
                    Back to Home
                  </a>
                </div>
                <div className="col-auto">
                  <button
                    className="btn btn-primary"
                    disabled={!updateEnabled}
                    onClick={saveUpdate}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
