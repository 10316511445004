import { useContext, useState } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";

import AuthContext from "../../context/AuthContext";
import ListContext from "../../context/ListContext";

export default function BookManageSlotModal({
  showSlotModal,
  setShowSlotModal,
  selSlot,
  getApptRange,
}) {
  const { token, user_id, role, fname, lname } = useContext(AuthContext);
  const { dentistList } = useContext(ListContext);

  const [selDent_id, setSelDent_id] = useState(dentistList[0]._id);

  // Dentist create schedule (slot by slot) for the week based on the set template: slotList
  const createAppt = async () => {
    const authStr = "Bearer " + token;
    const _dentist_id = role === "dentist" ? user_id : selDent_id;
    try {
      const resDoc = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/appt/staff/create",
        { ...selSlot, dentist_id: _dentist_id },
        {
          headers: { Authorization: authStr },
        }
      );
      if (resDoc.status === 201) {
        setShowSlotModal(false);
        getApptRange();
      } else {
        console.error("error creating appt");
      }
    } catch (error) {
      // possibly not authorised.
      console.error(error);
    }
  };

  return (
    <>
      <Modal show={showSlotModal} onHide={() => setShowSlotModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create a Vacant Slot</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-3 h5">Time:</div>
            <div className="col-9 h5">
              {selSlot.start.toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
              })}{" "}
              -{" "}
              {selSlot.end.toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
              })}
              , {selSlot.start.toLocaleDateString()}
            </div>
            {selSlot.start < new Date() && (
              <div className="alert alert-danger text-center">
                The selected slot is in the Past. Please select a future slot.
              </div>
            )}
            <div className="col-3 h5">Dentist:</div>
            <div className="col-9 h5">
              {role === "dentist" ? (
                <>
                  {fname} {lname}
                </>
              ) : (
                <div className="col-9">
                  <select
                    className="form-select"
                    onChange={(e) => setSelDent_id(e.target.value)}
                  >
                    {dentistList.map((dentist) => (
                      <option value={dentist._id} key={dentist._id}>
                        Dr {dentist.fname} {dentist.lname}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setShowSlotModal(false)}
          >
            Close
          </button>
          <button
            className="btn btn-primary"
            disabled={selSlot.start < new Date()}
            onClick={createAppt}
          >
            Create Appointment
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
