import { useContext, useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";

import AuthContext from "../../context/AuthContext";
import ListContext from "../../context/ListContext";

export default function BookManageApptModal({
  showModal,
  setShowModal,
  appt_id,
  getApptRange,
}) {
  const { token } = useContext(AuthContext);
  const { dentistList, reasonList } = useContext(ListContext);
  const [appt, setAppt] = useState();

  useEffect(() => {
    const fetchAppt = async () => {
      if (!token || !appt_id) return;
      const authStr = "Bearer " + token;
      try {
        const resDoc = await axios.get(
          process.env.REACT_APP_BACKEND_URL + `/appt/staff/${appt_id}`,
          {
            headers: { Authorization: authStr },
          }
        );
        if (resDoc.status === 200) {
          setAppt(resDoc.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchAppt();
  }, [token, appt_id]);

  const handleChange = (event) => {
    setAppt((prevEx) => {
      return {
        ...prevEx,
        [event.target.name]: event.target.value,
      };
    });
  };

  const dentistName = () => {
    const foundDentist = dentistList.find(
      (dentist) => dentist._id === appt.dentist_id
    );
    if (foundDentist) {
      return foundDentist.fname + " " + foundDentist.lname;
    }
    return "";
  };

  const cancelAppt = async () => {
    const authStr = "Bearer " + token;
    try {
      const resDoc = await axios.put(
        process.env.REACT_APP_BACKEND_URL + `/appt/staff/cancel/${appt_id}`,
        {
          note: appt.note, // this last note before cancel can be sent to user/admin via email for record
        },
        {
          headers: { Authorization: authStr },
        }
      );
      if (resDoc.status === 201) {
        setShowModal(false);
        // After selected an appointment, refresh the page to show new booking results
        // window.location.reload();
        getApptRange();
      } else {
        console.error(resDoc.status);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const saveNote = async () => {
    const authStr = "Bearer " + token;
    try {
      const resDoc = await axios.put(
        process.env.REACT_APP_BACKEND_URL + `/appt/staff/save/${appt_id}`,
        {
          note: appt.note,
        },
        {
          headers: { Authorization: authStr },
        }
      );
      if (resDoc.status === 201) {
        setShowModal(false);
        // After selected an appointment, refresh the page to show new booking results
        // window.location.reload();
        getApptRange();
      } else {
        console.error(resDoc.status);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Appointment Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {appt && (
            <div className="row">
              <div className="col-3 h5">Time:</div>
              <div className="col-9 h5">
                {new Date(appt.start).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}{" "}
                -{" "}
                {new Date(appt.end).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
                , {new Date(appt.start).toLocaleDateString()}
              </div>
              {new Date(appt.start) < new Date() && (
                <div className="alert alert-danger text-center">
                  The selected appointment is in the Past.
                </div>
              )}
              <div className="col-3 h5">Dentist:</div>
              <div className="col-9 h5">{dentistName()}</div>
              <div className="col-3 h5">User:</div>
              <div className="col-9 h5">
                {appt.user_id && appt.user_id.fname + " " + appt.user_id.lname}
              </div>
              <div className="col-3">phone:</div>
              <div className="col-9">{appt.user_id && appt.user_id.phone}</div>
              <div className="col-3">email:</div>
              <div className="col-9">{appt.user_id && appt.user_id.email}</div>
              <div className="col-3">patient:</div>
              <div className="col-9">
                {appt.patient && appt.patient.fname + " " + appt.patient.lname}
              </div>
              <div className="col-3">Reason:</div>
              <div className="col-9">
                {
                  reasonList.find((reason) => reason._id === appt.reason_id)
                    ?.name
                }
              </div>
              <div className="col-3">patient note:</div>
              <div className="col-9">{appt.patient && appt.patient.note}</div>
              <div className="col-3">Staff note:</div>
              <div className="col-9">
                <textarea
                  className="form-control"
                  name="note"
                  value={appt.note}
                  onChange={handleChange}
                />
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setShowModal(false)}
          >
            Close
          </button>
          <button className="btn btn-info" onClick={saveNote}>
            Save Note
          </button>
          <button
            className="btn btn-danger"
            disabled={new Date(appt && appt.start) < new Date()}
            onClick={cancelAppt}
          >
            Cancel Appointment
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
