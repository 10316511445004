import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import AuthContext from "../../context/AuthContext";

import UserManageColumn from "./UserManageColumn";
import UserManageItem from "./UserManageItem";
import UserManageEdit from "./UserManageEdit";
import UserManageAdd from "./UserManageAdd";

export default function UserManage() {
  const { token } = useContext(AuthContext);

  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      if (!token) return;
      const authStr = "Bearer " + token;
      try {
        const resDoc = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/user/all",
          {
            headers: { Authorization: authStr },
          }
        );
        setAllUsers([...resDoc.data]);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUsers();
  }, [token]);

  const [editUserId, setEditUserId] = useState("");
  const setEdit = (UserId) => {
    setEditUserId(UserId);
  };

  return (
    <div className="container-fluid">
      <h1 className="text-center">Manage Users</h1>
      <div className="container-fluid bg-light text-dark">
        <h5>Add a New User</h5>
        <UserManageAdd />
        <UserManageColumn />
        {allUsers.map((user, index) => {
          if (user._id === editUserId) {
            return <UserManageEdit key={index} index={index} user={user} />;
          } else {
            return (
              <UserManageItem
                key={user._id}
                index={index}
                user={user}
                setEdit={setEdit}
              />
            );
          }
        })}
      </div>
    </div>
  );
}
