import { useContext, useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";

import AuthContext from "../../context/AuthContext";
import ListContext from "../../context/ListContext";
import { Alert } from "react-bootstrap";

export default function BookManageVacantModal({
  showVacantEventModal,
  setShowVacantEventModal,
  appt_id,
  getApptRange,
}) {
  const { token } = useContext(AuthContext);
  const { reasonList, dentistList, insuranceList } = useContext(ListContext);
  const [appt, setAppt] = useState();
  const [searchText, setSearchText] = useState();
  const [foundUser, setFoundUser] = useState();
  const [newUser, setNewUser] = useState();
  const [validPhone, setValidPhone] = useState(false);
  const [errorMsg, setErrorMsg] = useState();

  const [option, setOption] = useState("userself");

  const [apptDentist, setApptDentist] = useState();
  const [availReasonList, setAvailReasonList] = useState([]);

  const [bookEnabled, setBookEnabled] = useState(false);
  const [regEnabled, setRegEnabled] = useState(false);
  const [searchEnabled, setSearchEnabled] = useState(false);

  useEffect(() => {
    const fetchAppt = async () => {
      if (!token || !appt_id) return;
      const authStr = "Bearer " + token;
      try {
        const resDoc = await axios.get(
          process.env.REACT_APP_BACKEND_URL + `/appt/staff/${appt_id}`,
          {
            headers: { Authorization: authStr },
          }
        );
        if (resDoc.status === 200) {
          setAppt(resDoc.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchAppt();
  }, [token, appt_id]);

  // After selected dentist - filter reasonList to the reasons which are avaible for the selected reason
  useEffect(() => {
    if (!appt) return;
    const selDentist = dentistList.find(
      (dentist) => dentist._id === appt.dentist_id
    );
    setApptDentist(selDentist);
    setAvailReasonList(
      reasonList.filter((reason) => selDentist.reason_list.includes(reason._id))
    );
  }, [appt, dentistList, reasonList]);

  // enable book button when patient data are complete
  useEffect(() => {
    setBookEnabled(
      appt && appt.patient && appt.patient.fname && appt.patient.lname
      // appt.patient.DOB
    );
  }, [appt]);

  useEffect(() => {
    setRegEnabled(
      newUser && newUser.fname && newUser.lname && isValidPhone(newUser.phone)
      // newUser.DOB
    );
  }, [newUser]);

  useEffect(() => {
    setSearchEnabled(
      searchText && (isValidPhone(searchText) || isValidEmail(searchText))
    );
  }, [searchText]);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleChange = (event) => {
    setAppt((prevEx) => {
      return {
        ...prevEx,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handlePatientChange = (event) => {
    setAppt((prevState) => {
      return {
        ...prevState,
        patient: {
          ...prevState.patient,
          [event.target.name]: event.target.value,
        },
      };
    });
  };

  const handleOptionChange = (changeEvent) => {
    const newOption = changeEvent.target.value;
    setOption(newOption);
    if (newOption === "other") {
      setAppt((prevState) => {
        return {
          ...prevState,
          patient: {
            fname: "",
            lname: "",
            DOB: "",
          },
        };
      });
    } else {
      setAppt((prevState) => {
        return {
          ...prevState,
          patient: {
            fname: foundUser.fname,
            lname: foundUser.lname,
            DOB: foundUser.DOB,
          },
        };
      });
    }
  };

  const handleNewUserChange = (event) => {
    setNewUser((prevEx) => {
      return {
        ...prevEx,
        [event.target.name]: event.target.value,
      };
    });
  };

  function isValidPhone(lineNr) {
    return /^(0[23478])([ -]?[0-9]){8}$/.test(lineNr);
  }
  const handleNewUserMobileChange = (event) => {
    if (isValidPhone(event.target.value)) {
      setValidPhone(true);
    } else {
      setValidPhone(false);
    }
    handleNewUserChange(event);
  };

  const registerUser = async () => {
    const authStr = "Bearer " + token;
    try {
      const resDoc = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/user/signUpByStaff",
        newUser,
        {
          headers: { Authorization: authStr },
        }
      );
      if (resDoc.status === 201) {
        setNewUser();
      } else {
        setErrorMsg("cannot register user");
      }
    } catch (error) {
      // Axios Handling Errors: https://axios-http.com/docs/handling_errors
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        setErrorMsg(error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log("Error", error.message);
        console.error(error);
      }
      // console.log(error.config);
    }
  };

  const findUser = async () => {
    const authStr = "Bearer " + token;
    const sTextTrim = searchText.replace(/\s/g, ""); // delete spaces, if any
    try {
      const resDoc = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
          `/user/getByEmailPhone/${sTextTrim}`,
        {
          headers: { Authorization: authStr },
        }
      );
      if (resDoc.status === 200) {
        // a user is found - list the user as potential target for this booking
        setFoundUser(resDoc.data);
        setAppt((prevState) => {
          return {
            ...prevState,
            user_id: resDoc.data._id,
            reason_id: reasonList.find(
              (reason) => reason.name === "Unspecified"
            )._id,
            patient: {
              fname: resDoc.data.fname,
              lname: resDoc.data.lname,
              DOB: resDoc.data.DOB,
            },
          };
        });
      } else {
        console.error("cannot search");
      }
    } catch (error) {
      // axios Handling Errors: https://axios-http.com/docs/handling_errors
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        if (error.response.status === 404) {
          // cannot find user - need to create a new user for this booking
          setFoundUser();
          if (sTextTrim.includes("@")) {
            setNewUser({
              email: sTextTrim,
              insurance_id: insuranceList.find(
                (insurance) => insurance.name === "Unspecified"
              )._id,
            });
          } else {
            setNewUser({
              phone: sTextTrim,
              insurance_id: insuranceList.find(
                (insurance) => insurance.name === "Unspecified"
              )._id,
            });
            setValidPhone(true);
          }
        } else {
          setErrorMsg(error.response.data);
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.error("Error", error.message);
        console.error(error);
      }
      // console.error(error.config);
    }
  };

  const bookAppt = async () => {
    const authStr = "Bearer " + token;
    try {
      const resDoc = await axios.put(
        process.env.REACT_APP_BACKEND_URL + `/appt/staff/book/${appt_id}`,
        appt,
        {
          headers: { Authorization: authStr },
        }
      );
      if (resDoc.status === 201) {
        setShowVacantEventModal(false);
        // After selected an appointment, refresh the page to show new booking results
        // window.location.reload();
        getApptRange();
      } else {
        console.error(resDoc.status);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteAppt = async () => {
    const authStr = "Bearer " + token;
    try {
      const resDoc = await axios.delete(
        process.env.REACT_APP_BACKEND_URL + `/appt/delete/${appt_id}`,
        {
          headers: { Authorization: authStr },
        }
      );
      if (resDoc.status === 201) {
        setShowVacantEventModal(false);
        // After selected an appointment, refresh the page to show new booking results
        // window.location.reload();
        getApptRange();
      } else {
        console.error(resDoc.status);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        show={showVacantEventModal}
        // size="lg"
        onHide={() => setShowVacantEventModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Book This Timeslot</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {appt && (
            <div className="row">
              <div className="col-3 h5">Time:</div>
              <div className="col-9 h5">
                {new Date(appt.start).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}{" "}
                -{" "}
                {new Date(appt.end).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
                , {new Date(appt.start).toLocaleDateString()}
              </div>
              {new Date(appt.start) < new Date() && (
                <div className="alert alert-danger text-center">
                  The selected appointment is in the Past.
                  <br />
                  Please select a future appointment.
                </div>
              )}
              <div className="col-3 h5">Dentist</div>
              <div className="col-9 h5">
                {apptDentist && apptDentist.fname}{" "}
                {apptDentist && apptDentist.lname}
              </div>
              <div className="col-3 h5">Find user:</div>
              <div className="col-6 mb-2">
                <input
                  className="form-control"
                  type="text"
                  name="email"
                  disabled={new Date(appt.start) < new Date()}
                  placeholder="user email or phone"
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
              <div className="col-3">
                <button
                  className="btn btn-info"
                  disabled={!searchEnabled}
                  onClick={findUser}
                >
                  Search
                </button>
              </div>

              {foundUser ? (
                <div className="col-12">
                  <table>
                    <tbody>
                      <tr className="h5">
                        <th>User&nbsp;Name:</th>
                        <td>{foundUser.fname + " " + foundUser.lname}</td>
                      </tr>
                      <tr>
                        <th>email:</th>
                        <td>{foundUser.email}</td>
                      </tr>
                      <tr>
                        <th>phone:</th>
                        <td>{foundUser.phone}</td>
                      </tr>
                      <tr className="h5" style={{ height: "3rem" }}>
                        <th>Patient:</th>
                        <td className="w-100">
                          <div className="row">
                            <div className="col-6">
                              <div className="form-check">
                                <label>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="react-tips"
                                    value="userself"
                                    checked={option === "userself"}
                                    onChange={handleOptionChange}
                                  />
                                  Userself
                                </label>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-check">
                                <label>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="react-tips"
                                    value="other"
                                    checked={option === "other"}
                                    onChange={handleOptionChange}
                                  />
                                  Someone else
                                </label>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      {option === "userself" ? (
                        <>
                          <tr>
                            <th>Patient&nbsp;Name:</th>
                            <td>
                              {appt.patient.fname + " " + appt.patient.lname}
                            </td>
                          </tr>
                          <tr>
                            <th>Patient&nbsp;DOB:</th>
                            <td>
                              {new Date(appt.patient.DOB).toLocaleDateString()}
                            </td>
                          </tr>
                        </>
                      ) : (
                        <>
                          <tr>
                            <th className="text-end">Patient&nbsp;Name:</th>
                            <td>
                              <div className="text-muted">
                                Enter one patient per appointment.
                              </div>
                              <input
                                className="form-control mb-2"
                                required
                                type="text"
                                name="fname"
                                onChange={handlePatientChange}
                                placeholder="👤 First name*"
                              />
                              <input
                                className="form-control mb-2"
                                required
                                type="text"
                                name="lname"
                                onChange={handlePatientChange}
                                placeholder="Last name 👤*"
                              />
                            </td>
                          </tr>

                          <tr>
                            <th className="text-end">DOB:</th>
                            <td>
                              <div className="col-lg-6">
                                <input
                                  className="form-control mb-2"
                                  required
                                  type="date"
                                  name="DOB"
                                  onChange={handlePatientChange}
                                />
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                      <tr>
                        <th>Reason:</th>
                        <td>
                          <select
                            className="form-select mb-2"
                            defaultValue="any"
                            name="reason_id"
                            onChange={handleChange}
                          >
                            <option value="any">Any Reason</option>
                            {availReasonList.length > 0 &&
                              availReasonList.map((reason) => (
                                <option value={reason._id} key={reason._id}>
                                  {reason.name}
                                </option>
                              ))}
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <th>Note:</th>
                        <td>
                          <textarea
                            className="form-control"
                            name="note"
                            value={appt.note}
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                newUser && (
                  <div className="row">
                    <div className="col-12 text-muted text-center">
                      User doen't exist. Create a New User
                    </div>
                    <div className="col-6 mb-2">
                      <input
                        className="form-control"
                        required
                        type="text"
                        name="fname"
                        onChange={handleNewUserChange}
                        placeholder="👤 First name*"
                      />
                    </div>
                    <div className="col-6 mb-2">
                      <input
                        className="form-control"
                        required
                        type="text"
                        name="lname"
                        onChange={handleNewUserChange}
                        placeholder="Last name 👤*"
                      />
                    </div>
                    <div className="col-6">
                      <input
                        className="form-control"
                        type="text"
                        name="phone"
                        value={newUser.phone || ""}
                        onChange={handleNewUserMobileChange}
                        placeholder="☎︎ Mobile* 0450123456"
                      />
                      {!validPhone && (
                        <small style={{ color: "red" }}>
                          enter valid mobie number:
                          <br />
                          e.g., 0450123456
                        </small>
                      )}
                    </div>

                    <div className="col-6">
                      <input
                        className="form-control"
                        required
                        type="email"
                        name="email"
                        value={newUser.email || ""}
                        onChange={handleNewUserChange}
                        placeholder="📧 email"
                      />
                    </div>

                    <div className="col-6 mb-3">
                      <label>
                        <small> Date of Birth:</small>
                        <input
                          className="form-control"
                          required
                          type="date"
                          name="DOB"
                          onChange={handleNewUserChange}
                        />
                      </label>
                    </div>
                    <div className="col-6">
                      <label>
                        <small>Insurance:</small>
                        <select
                          className="form-select"
                          name="insurance_id"
                          value={newUser.insurance_id}
                          onChange={handleNewUserChange}
                        >
                          {insuranceList.map((insurance) => (
                            <option
                              key={insurance._id}
                              value={insurance._id}
                              // selected={editUser.insurance_id._id === insurance._id}
                            >
                              {insurance.name}
                            </option>
                          ))}
                        </select>
                      </label>
                    </div>
                    <div className="col-12 text-end">
                      <button
                        className="btn btn-primary"
                        disabled={!regEnabled}
                        onClick={registerUser}
                      >
                        Register this User
                      </button>
                    </div>
                  </div>
                )
              )}
              <Alert
                show={!!errorMsg}
                variant="danger"
                onClose={() => setErrorMsg("")}
                dismissible
              >
                Error: {errorMsg}.
              </Alert>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setShowVacantEventModal(false)}
          >
            Close
          </button>
          <button
            className="btn btn-success"
            disabled={!bookEnabled}
            onClick={bookAppt}
          >
            Book Appointment
          </button>
          <button
            className="btn btn-danger"
            disabled={new Date(appt && appt.start) < new Date()}
            onClick={deleteAppt}
          >
            Delete Appointment
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
