import axios from "axios";
import { useContext } from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";

import AuthContext from "../../context/AuthContext";

export default function ManageServiceItem({ index, service, setEdit }) {
  const { token } = useContext(AuthContext);

  async function deleteService(service_id) {
    const authStr = "Bearer " + token;
    try {
      const resDoc = await axios.delete(
        process.env.REACT_APP_BACKEND_URL + "/service/delete/" + service_id,
        {
          headers: { Authorization: authStr },
        }
      );
      if (resDoc.status === 201) {
        // service deleted - refresh page
        window.location.reload();
      } else {
        console.error(resDoc.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className={index % 2 ? "row mt-2 bg-white" : "row mt-2"}>
      <div className="col-1">{service.name}</div>
      <div className="col-1">{service.seq}</div>
      <div className="col-1">
        <img
          className="img-fluid"
          src={`${process.env.REACT_APP_ASSET_URL}/${service.iconPath}`}
          alt="service icon"
        />
      </div>
      <div className="col-2">
        <img
          className="img-fluid"
          src={`${process.env.REACT_APP_ASSET_URL}/${service.imgPath}`}
          alt="service img"
        />
      </div>
      <div className="col-2">{service.shortNote}</div>
      <div className="col-3">{service.note}</div>
      <div className="col-1">{service.status}</div>

      <div className="col-1 text-center">
        <button className="btn btn-link" onClick={() => setEdit(service._id)}>
          <FaEdit />
        </button>
        <button
          className="btn btn-link"
          disabled={service.status !== "inactive"}
          onClick={() => deleteService(service._id)}
        >
          <FaTrashAlt />
        </button>
      </div>
    </div>
  );
}
