import React from "react";

export default function StaffManageColumn() {
  return (
    <div className="row bg-primary text-white font-weight-bold">
      <div className="col-1">title</div>
      <div className="col-1">FirstName</div>
      <div className="col-1">LastName</div>
      <div className="col-2">Position</div>
      <div className="col-2">email</div>
      <div className="col-1">phone</div>
      <div className="col-1">seq</div>
      <div className="col-1">status</div>
      <div className="col-1">role</div>
      <div className="col-1">action</div>
    </div>
  );
}
