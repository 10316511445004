import React, { useContext } from "react";
import { BsPersonSquare } from "react-icons/bs";

import "./AboutUs.css";
import ListContext from "../../context/ListContext";

export default function AboutUsBody() {
  const { staffList } = useContext(ListContext);

  return (
    <div className="container py-3">
      <div className="row">
        <h3>Welcome to Epping Dental Centre</h3>
        <div className="col-lg-6">
          <p>
            Established in 1993, Epping Dental Centre has been creating and
            maintaining beautiful smiles in the local community for over 30
            years.
          </p>
          <p>
            At Epping Dental Centre, we believe that everyone deserves the
            confidence of a beautiful healthy smile. Our team of experienced
            dental professionals are committed to helping you achieve your
            dental goals. We offer a comprehensive range of dental services from
            routine check-ups to complex restorative rehabilitations. Using the
            latest techniques and technologies, we aim to make your dental
            journey effective, efficient and enjoyable.{" "}
            <a
              style={{ color: "inherit" }}
              href={"tel:" + process.env.REACT_APP_PHONE}
            >
              Call us
            </a>{" "}
            today or{" "}
            <a style={{ color: "inherit" }} href="/book-now">
              Book Now
            </a>{" "}
            to take your first step toward better oral health.
          </p>
          <h5> Why Choose Us</h5>
          <ul style={{ listStyle: "none", paddingLeft: 0 }}>
            <li>
              <b>Care</b> - Every patient at Epping Dental Centre receives
              professional personalised care. Tailor made long-term treatment
              plans are created to meet your unique needs.
            </li>
            <li>
              <b>Convenience</b> - We understand you are busy, and life can get
              in the way of your dental health. Epping Dental Centre is open 7
              days a week with afterhours appointments available.
            </li>
            <li>
              <b>Cost</b> - Epping Dental Centre provides a range of dental
              solutions to suit all budgets.
            </li>
          </ul>
        </div>
        <div className="col-lg-6">
          <img
            className="p-3"
            src="/images/epping-dental-centre.jpg"
            alt="Epping Dental Centre"
            width="100%"
          />
        </div>
      </div>
      <br />

      <h3>We Accept All Major Health Funds</h3>
      <p>
        We are Bupa Members First Network Provider and Medibank Members Choice
        Advantage Provider.
      </p>
      <div className="row">
        <div className="col-6 col-lg-4 text-center">
          <a href="https://www.bupa.com.au/" target="_blank" rel="noreferrer">
            <img src={require("./insurer-Bupa.png")} width="50%" alt="bupa" />
          </a>
        </div>
        <div className="col-6 col-lg-4 text-center">
          <a
            href="https://www.medibank.com.au/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={require("./insurer-Medibank.jpg")}
              width="100%"
              alt="bupa"
            />
          </a>
        </div>
      </div>
      <br />

      <h3>Meet Our Dentists</h3>
      <div className="row">
        {staffList
          .filter((staff) => staff.role === "dentist")
          .map((member) => (
            <div className="col-6 col-lg-4" key={member._id}>
              <div className="card" id="aboutus-card">
                <div className="card-body">
                  <div className="img-container m-3">
                    <a href={`/aboutus/${member.fname}${member.lname}`}>
                      {member.photoPath ? (
                        <img
                          className="staff-img"
                          src={
                            process.env.REACT_APP_ASSET_URL +
                            "/" +
                            member.photoPath
                          }
                          alt="staff img"
                        />
                      ) : (
                        <>
                          <h1>
                            <BsPersonSquare />
                          </h1>
                          <p>Dentist photo</p>
                        </>
                      )}
                    </a>
                  </div>
                  <h5>
                    {member.title} {member.fname} {member.lname.toUpperCase()}
                  </h5>
                  <div>{member.position}</div>
                </div>
              </div>
            </div>
          ))}
      </div>

      {/* <h3>Staff</h3>
      <div className="row">
        {staffList
          .filter((staff) => staff.role !== "dentist")
          .map((member) => (
            <div className="col-6 col-lg-4" key={member._id}>
              <div className="card" id="aboutus-card">
                <div className="card-body">
                  {member.photoPath ? (
                    <img
                      src={
                        process.env.REACT_APP_ASSET_URL + "/" + member.photoPath
                      }
                      id="aboutus-card-img"
                      alt="img"
                    />
                  ) : (
                    <>
                      <h1>
                        <BsPersonSquare />
                      </h1>
                      <p>Staff photo</p>
                    </>
                  )}
                  <h5>
                    {member.title} {member.fname} {member.lname.toUpperCase()}
                  </h5>
                  <div>{member.position}</div>
                </div>
              </div>
            </div>
          ))}
      </div> */}
    </div>
  );
}
